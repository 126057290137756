import React, { useState } from "react";
import DocumentCenter from "../../../personal-profile/DocumentCenter";
import ProfessionalMemberships from "./ProfessionalMemberships";
import Specialties from "./Specialties";
import ProfessionalExperience from "./ProfessionalExperience";
import LanguageSpoken from "./LanguageSpoken";
import ConsultationHoursForPatient from "./ConsultationHoursForPatient";
import classNames from "classnames";
import ProfileForPsychiatristApps from "./ProfileForPsychiatristApps";
import ProfileForPatients from "../../md-users/edit-mode/ProfileForPatients";

function MainAPPEdit({ setIsEditing }) {
  const isValid = true;

  return (
    <div className="w-full">
      <DocumentCenter />

      <ProfessionalMemberships />
      <Specialties />
      <ProfessionalExperience />
      <LanguageSpoken />
      {/* <ConsultationHoursForPatient /> */}
      <ProfileForPatients />
      <ProfileForPsychiatristApps />
      <div className="flex justify-center">
        <button
          onClick={() => setIsEditing(false)}
          className={classNames(
            {
              "text-light_tone_one cursor-not-allowed ": !isValid,
              "submit-btn-bg-color text-white": isValid,
            },
            "border  py-3 px-5 rounded-xl w-72"
          )}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default MainAPPEdit;
