import React, { useEffect } from "react";
import useGetLocalUserDetails from "../../../../../hooks/useGetLocalUserDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfilePicture from "../../../../../components/ProfilePicture";
import { Link } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useCollabActions from "../../../../../actions/useCollabActions";
import { useContext } from "react";
import { AuthContext } from "../../../../../App";
import { useState } from "react";
import { Divider, Skeleton } from "@mui/material";

const AppWidget = () => {
  const { getExitingCollabs } = useCollabActions();
  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const [collaborators, setList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getExitingCollabs()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  return (
    <>
      <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-full relative">
        <div className="text-subtitle_one">Collaboration </div>
        <div className="my-4 w-full">
          <div className="flex justify-between px-2 max-md:flex-col max-md:p-0">
            <div className="text-mid_tone_two">
              <div className="flex gap-2">
                <div>5.5%</div>
                <div>14 days Target</div>
                <div>7%</div>
              </div>
              <input
                type="range"
                min="0"
                max="100"
                value="66"
                readOnly
                className="w-36"
                id="rangeForCollaborator"
              />
            </div>
            <div className="bg-light_tone_one p-2 flex gap-1 items-center rounded-lg shadow-sm">
              <FontAwesomeIcon
                icon={faSearch}
                className="h-5 w-5 text-gray-400 border  rounded-full "
                aria-hidden="true"
              />
              <input
                type="search"
                className="bg-light_tone_one p-1"
                placeholder="Find New Collaborator"
              />
            </div>
          </div>
          <div className="flex gap-2  items-center ">
            <div className="flex flex-col gap-2 h-48 overflow-auto w-full">
              <div className="text-gray_out_text">Collaborator Partners</div>
              <Divider />

              {isLoading ? (
                <>
                  <>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={40}
                    />
                  </>
                </>
              ) : null}
              {collaborators.map((x) => {
                return (
                  <div className="font-normal ">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <ProfilePicture />
                        Joy Abbott
                      </div>
                      <img
                        src={"/assets/icons/provider/new-email.png"}
                        className="w-4 h-4 mr-5"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="absolute right-3 bottom-1">
            <div className="flex justify-end mb-1">
              <Link to="details">
                <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                  View Details
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MdWidget = () => {
  const { getExitingCollabs, getCollabsRequests } = useCollabActions();
  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const [pending, setPending] = useState(0);
  const [collaborators, setList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getExitingCollabs()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    getCollabsRequests().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setPending(res.data.length);
        }
      }
    });
  }, []);
  return (
    <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96 relative">
      <div className="text-subtitle_one">Collaboration </div>
      <div className="my-4 w-full">
        <div className="flex gap-2  items-center ">
          <div className="flex flex-col gap-2 h-48 overflow-auto w-full">
            <div className="  font-normal flex justify-between ">
              <div className="bg-light_tone_one p-2 flex gap-3 rounded-sm shadow-sm">
                <span>{pending ?? 0} | Pending</span>
                <span>{collaborators?.length ?? 0} | Complete</span>
              </div>
              <Link
                to="/provider/care-collaboration/details#collab-requests"
                className="flex items-center gap-2 "
              >
                <div className="relative">
                  {pending ? (
                    <>
                      <ProfilePicture />
                      <span className="bg-complementary_accent_two rounded-full text-yellow-800 text-xs font-medium me-2 p-1 absolute top-1 left-8 z-10">
                        {pending}
                      </span>
                      &nbsp; &nbsp; &nbsp;
                    </>
                  ) : (
                    <div className="text-mid_tone_two">No </div>
                  )}
                </div>

                <div className="text-mid_tone_two">New Request</div>
              </Link>
            </div>
            <Divider />
            {isLoading ? (
              <>
                <>
                  <Skeleton variant="rectangular" width={"100%"} height={40} />
                  <Skeleton variant="rectangular" width={"100%"} height={40} />
                  <Skeleton variant="rectangular" width={"100%"} height={40} />
                  <Skeleton variant="rectangular" width={"100%"} height={40} />
                </>
              </>
            ) : null}
            {collaborators && collaborators.length === 0
              ? "No collaborators"
              : null}
            {collaborators.map((x, idx) => {
              return (
                <div key={idx}>
                  <div className="font-normal ">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <ProfilePicture picture={x.picture} />
                        {x.name}
                        {x.full_name}
                      </div>
                      <img
                        src={"/assets/icons/provider/new-email.png"}
                        className="w-4 h-4 mr-5"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <div className="absolute right-3 bottom-1">
          <div className="flex justify-end mb-1">
            <Link to="/provider/care-collaboration/details">
              <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function CollaborationWidget() {
  const { isMd } = useGetLocalUserDetails();

  return isMd ? <MdWidget /> : <AppWidget />;
}

export default CollaborationWidget;
