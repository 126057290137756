import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Link } from "react-router-dom";

import DashboardHoc from "../../DashboardHoc";
import ProfilePicture from "../../../../components/ProfilePicture";
import { Button, Rating } from "@mui/material";
import useProviderActions from "../../../../actions/useProviderActions";

const columns = [
  { id: "Review", label: "Review", minWidth: 500 },
  { id: "Rating", label: "Rating", minWidth: 100 },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
];

function ReviewManagement() {
  const page = 0,
    rowsPerPage = 100;
  const handleChangePage = () => {};
  const handleChangeRowsPerPage = () => {};

  const [reviews, setReviews] = React.useState([]);
  const { getReviews } = useProviderActions();

  React.useEffect(() => {
    getReviews().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setReviews(res.data);
        }
      }
    });
  }, []);

  return (
    <DashboardHoc>
      <div className="w-full">
        <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5 ">
          <div className="text-3xl capitalize">Performance And Insights</div>
          <div className="text-body_font_three max-md:flow-root  max-md:px-5">
            Manage your information, compliance, appointments, requests,
            security, set your availability and more to make your work smoother.
            <Link to="#" className="text-blue-600">
              Find out more
            </Link>
          </div>
          <section className="w-full px-12  flex  gap-4 max-md:p-2 ">
            <div className="bg-white  shadow-xl   max-md:p-2  rounded-lg p-4 flex w-full max-md:w-full   flex-col max-md:h-96">
              <div className="text-subtitle_one">Review Management</div>

              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reviews
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map(
                          (
                            {
                              created_at,
                              patient_name,
                              patient_picture,
                              rating,
                              review,
                              status,
                            },
                            idx
                          ) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={idx}
                                key={idx}
                              >
                                <TableCell>
                                  <span className="flex gap-3">
                                    {patient_picture ? (
                                      <img
                                        src={patient_picture}
                                        width="35px"
                                        height="35px"
                                      />
                                    ) : (
                                      <ProfilePicture
                                        name={patient_name}
                                        width="35px"
                                        height="35px"
                                      />
                                    )}
                                    <div className="flex flex-col">
                                      <div className="flex gap-8">
                                        <span className="font-semibold">
                                          {patient_name} 
                                        </span>

                                        <span>{created_at}</span>
                                      </div>
                                      <span>{review}</span>
                                    </div>
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <div className="flex gap-1 items-center">
                                    <span className="text-body_font_three">
                                      {rating}
                                    </span>
                                    <Rating
                                      name="read-only"
                                      value={rating}
                                      readOnly
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {status === "pending" ? (
                                    <div className="flex  gap-2">
                                      <button className="border p-2 text-mid_tone_two">
                                        Approve
                                      </button>
                                      <button className="text-complementary_accent_three border p-2 btn">
                                        Appeal
                                      </button>
                                    </div>
                                  ) : status === "approved" ? (
                                    <button className="text-mid_tone_three">
                                      Approved
                                    </button>
                                  ) : (
                                    <button className="text-complementary_accent_three">
                                      Rejected
                                    </button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>
            </div>
          </section>
        </div>
      </div>
    </DashboardHoc>
  );
}

export default ReviewManagement;
