import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function useAuth() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const signupByEmail = async (data, userType) => {
    const payload = {
      email: data.email,
      password: data.password,
      authtype: "email",
      usertype: userType,
    };

    const rawResponse = await fetch(`${baseURL}/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const response = await rawResponse.json();
    if (response.status === "failed") {
      toast.error(response.messages);
      return response;
    }
    const authToken = response?.data?.auth_token;

    if (authToken) {
      localStorage.setItem("auth-token", authToken);
      localStorage.setItem("login-by", "email");
      localStorage.setItem("user-details", JSON.stringify(response.data));
      window.location.assign("/");
    } else {
      toast.error("Unable to Signup---");
    }
  };
  const loginByEmail = async (data, userType) => {
    const payload = {
      email: data.email,
      password: data.password,
      authtype: "email",
    };

    const rawResponse = await fetch(`${baseURL}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const response = await rawResponse.json();
    if (response.status === "failed") {
      toast.error(response?.messages);
      return response;
    }
    const authToken = response?.data?.auth_token;
    if (authToken) {
      localStorage.setItem("login-by", "email");
      localStorage.setItem("user-details", JSON.stringify(response.data));
      localStorage.setItem("auth-token", authToken);

      window.location.assign("/");
    } else {
      toast.error("Unable to login");
      return response;
    }
  };
  const loginByGoogle = async (data, userType) => {
    const { email } = data;
    const payload = {
      email,
      password: "",
      authtype: "gmail",
      authid: data.id,
      first_name: data.given_name,
      last_name: data.family_name,
      usertype: userType,
      picture: data.picture,
      gender: data.gender,
    };

    const rawResponse = await fetch(`${baseURL}/authenticate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const content = await rawResponse.json();
    const authToken = content?.data?.auth_token;
    if (authToken) {
      localStorage.setItem("login-by", "email");
      localStorage.setItem("user-details", JSON.stringify(content.data));
      localStorage.setItem("auth-token", authToken);
      window.location.assign("/");
    } else {
      toast.error("Unable to login");
    }

    window.location.assign("/");
  };

  const getGoogleUserDetails = async (token, isAutoCalled, userType) => {
    if (isAutoCalled) {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      return await response.json();
    } else {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          loginByGoogle(data, userType);
          localStorage.setItem("login-by", "google");
        });
    }
  };

  return { signupByEmail, loginByGoogle, getGoogleUserDetails, loginByEmail };
}

export default useAuth;
