import React, { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AuthContext } from "../../../App";
import { Link } from "react-router-dom";
import DashboardHoc, { CongratsModal } from "../DashboardHoc";
import useProviderActions from "../../../actions/useProviderActions";
import { defaultWidgets, widgetNames, widgets } from "./widgets/utils";
import ModalComponent from "../../../components/modals/ModalComponent";
import { Add, BorderAllRounded, Edit } from "@mui/icons-material";
import UpdateWidgetsForm from "./widgets/UpdateWidgetsForm";
import ProfilePicture from "../../../components/ProfilePicture";
import useGetLocalUserDetails from "../../../hooks/useGetLocalUserDetails";
import EmailVerificationModal from "../../../components/modals/EmailVerificationModal";

function ProviderDashboard() {
  const { isAuth, userDetails } = useContext(AuthContext);
  const [showCongrats, setShowCongrats] = useState(
    userDetails.welcome_message_displayed != "1"
  );
  const { isMd } = useGetLocalUserDetails();
  const [widgetsList, setWidgetList] = useState([
    {
      id: 1,
      widget_id: "1",
      position: "1",
    },
    {
      id: 2,
      widget_id: "2",
      position: "2",
    },
  ]);
  const { getWidgets, updateWidgets } = useProviderActions();
  const [isUpdateWidgets, setUpdatingWidgets] = useState(false);
  const onUpdateWidget = (data) => {
    const payload = {
      widgets: data.map((id, pos) => {
        return {
          widget_id: id,
          position: pos + 1,
        };
      }),
      user_id: Number(userDetails.user_id),
    };
    updateWidgets(payload).then(() => {
      window.location.assign("/");
    });
  };
  useEffect(() => {
    getWidgets().then((res) => {
      if (res.status === 200) {
        if (res.data && res.data.length) {
          setWidgetList(res.data.sort((a, b) => a.position - b.position));
        } else {
          setWidgetList(defaultWidgets);
        }
      }
    });
  }, []);
  const getComponent = (id) => {
    const currentWidget = widgets.find((x) => x.id == id);
    if (currentWidget && currentWidget.component) {
      return currentWidget.component;
    } else {
      return null;
    }
  };
  return (
    <DashboardHoc>
      <CongratsModal
        isOpen={showCongrats}
        close={() => setShowCongrats(false)}
      />
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5 max-md:w-full max-md:p-2  ">
        <div
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
          }}
        >
          <div style={{ position: "absolute" }}>
            <ProfilePicture
              width={96}
              height={96}
              classes={"rounded-full"}
              picture={userDetails.picture}
            />
          </div>
          <div style={{ position: "absolute" }}>
            <CircularProgressbar
              value={20}
              styles={buildStyles({
                // pathColor:"#EF8C89"
                // pathColor:"#CDC270"
                rotation: 0.5,
                pathColor: "#D82022",
              })}
            />
          </div>
          <div className="absolute bottom-0 left-5">
            <span
              className="bg-white px-3 pb-2 rounded-t-md rounded-b-md text-body_font_three"
              style={{ color: "#D82022" }}
            >
              20%
            </span>
          </div>
        </div>
        <div className="text-3xl capitalize max-md:px-2">
          Welcome,{` ${userDetails.prefix} ${userDetails.first_name}`}
        </div>
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          <div>
            Manage your information, compliance, appointments, requests,
            security, set your availability and more to make your work smoother.{" "}
            <Link to="#" className="text-blue-600">
              Find out more
            </Link>
          </div>
           
        </div>
        {isMd &&
        userDetails?.provider_details?.controlled_substance_status ===
          "pending" ? (
          <div className="bg-white  shadow-xl rounded-lg p-4 flex w-full max-md:flex max-md:flex-col max-md:align-middle gap-2 items-center justify-center">
            <div className="text-body_font_three  ">
              Please verify DEA license Number to complete registration.
              Compliance is mandatory. Without it, prescribing Controlled
              Substances is not permitted.
            </div>
            <Link to="/provider/profile-management/medical-profile">
              <button className="submit-btn-bg-color p-2 text-white w-40 rounded-lg text-body_font_two max-md:w-full">
                Complete Now
              </button>
            </Link>
          </div>
        ) : null}
        <div
          className=" flex w-full  gap-3 max-md:flex-col max-md:px-2"
          style={{ maxHeight: "500px", minHeight: "500px" }}
        >
          {widgetsList && widgetsList[0]
            ? getComponent(widgetsList[0].widget_id)
            : null}
          {widgetsList && widgetsList[1]
            ? getComponent(widgetsList[1].widget_id)
            : null}
        </div>{" "}
        <div
          style={{ maxHeight: "500px", minHeight: "500px" }}
          className=" flex w-full  gap-3 max-md:flex-col"
        >
          {widgetsList && widgetsList[2] ? (
            getComponent(widgetsList[2].widget_id)
          ) : (
            <div
              onClick={() => setUpdatingWidgets(3)}
              className="bg-gray_out gap-5 items-center 
        cursor-pointer
        shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full h-36"
            >
              <Add />
              <div className="text-subtitle_one">Add More Widget</div>
            </div>
          )}
          {widgetsList && widgetsList[3]
            ? getComponent(widgetsList[3].widget_id)
            : widgetsList.length === 3 && (
                <div
                  onClick={() => setUpdatingWidgets(4)}
                  className="bg-gray_out gap-5 items-center 
        cursor-pointer
        shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full "
                >
                  <Add />
                  <div className="text-subtitle_one">Add More Widget</div>
                </div>
              )}
        </div>
      </div>
      <ModalComponent
        isOpen={Boolean(isUpdateWidgets)}
        className={
          "w-2/3 absolute bg-complementary_accent_one border-none  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <UpdateWidgetsForm
              close={() => {
                setUpdatingWidgets(false);
              }}
              onUpdate={onUpdateWidget}
              defaultChecked={
                widgetsList
                  ? widgetsList.map(({ widget_id }) => Number(widget_id))
                  : [1, 2]
              }
              enableVal={isUpdateWidgets}
            />
          </div>
        }
      />
    </DashboardHoc>
  );
}

export default ProviderDashboard;
