import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import useProviderActions from "../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";

const StatePicker = ({
  isDisabled,
  showLabel = true,
  onChange,
  defaultValue,
  classnames,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getStates } = useProviderActions();
  useEffect(() => {
    // Function to fetch data from API
    const fetchStates = async () => {
      try {
        const states = await getStates();

        const formattedOptions = states.map((state) => ({
          value: state,
          label: state,
        }));

        setOptions(formattedOptions);
        setLoading(false);
      } catch (error) {
        toast.error("Error fetching states:", error);
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  return (
    <>
      {showLabel ? <label htmlFor={"state"}>State</label> : null}

      {loading ? (
        <Skeleton variant="rectangular" width={210} height={40} />
      ) : (
        <Select
          options={options}
          id="state"
          isDisabled={isDisabled}
          classNames={{
            control: (state) =>
              "w-48 max-md:w-80 h-12 rounded-xl select-box-upload-doc mt-2 px-4" +
              classnames,
          }}
          defaultValue={defaultValue}
          placeholder="Update state"
          onChange={(e) => {
            onChange(e.label);
          }}
        />
      )}
    </>
  );
};

export default StatePicker;
