import React from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DashboardHoc from "../../DashboardHoc";
import MyPatientList from "../widgets/MyPatientList";
import CollaborationWidget from "../profile/personal-profile/CollaborationWidget";
import useWindowDimensions from "../../../utils/useWindowDimensions";

function CareAndCollaboration() {
  const [width] = useWindowDimensions();

  return (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5  max-md:w-full max-md:h-full max-md:p-2">
        <div className="text-3xl capitalize">Collaboration</div>
        <div className="text-body_font_three  max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        {width > 768 ? (
          <div
            style={{ maxHeight: "500px", minHeight: "500px" }}
            className="flex w-full  gap-3 max-md:flex-col"
          >
            <MyPatientList />
            <CollaborationWidget />
          </div>
        ) : (
          <div className="flex w-full  gap-3 flex-col px-2">
            <MyPatientList />
            <CollaborationWidget />
          </div>
        )}
         
      </div>
    </DashboardHoc>
  );
}

export default CareAndCollaboration;
