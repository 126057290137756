import React from "react";

import Select from "react-select";
import classNames from "classnames";

import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";
function MyStatesApp() {
  const { userDetails } = React.useContext(AuthContext);

  const [isLoading, setLoading] = React.useState(true);
  const { getMyStats } = useProviderActions();
  const [data, setData] = React.useState([]);
  const getData = (id) => {
    setLoading(true);
    getMyStats(id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setData(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (userDetails && userDetails.user_id) getData("14days");
  }, []);
  return (
    <div className="bg-white w-full h-48 rounded-lg shadow-lg max-md:h-full  max-md:w-full">
      <div className="flex gap-1.5 items-start mt-4  max-md:flex-col max-md:px-4">
        <div className="text-subtitle_one px-4 ">My Stats</div>
        <Select
          classNames={{
            control: () => classNames({}, "w-36 rounded-lg   rounded-xl"),
          }}
          onChange={(e) => {
            getData(e.value);
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          defaultValue={{
            label: "14 Days",
            value: "14days",
          }}
          options={[
            {
              label: "14 Days",
              value: "14days",
            },
            {
              label: "Last month",
              value: "lastmonth",
            },
            {
              label: "Last 12 Month",
              value: "last12month",
            },
            {
              label: "All Time",
              value: "alltime",
            },
          ]}
        />
      </div>
      <br />
      {isLoading ? (
        <div className="flex justify-between w-full px-10">
          <Skeleton variant="rectangular" width={"20%"} height={100} />
          <Skeleton variant="rectangular" width={"20%"} height={100} />
          <Skeleton variant="rectangular" width={"20%"} height={100} />
        </div>
      ) : (
        <div className="flex justify-between max-md:flex-col max-md:gap-4 max-md:items-start max-md:py-4">
          <div className="flex px-4 ">
            <img
              src={"/assets/icons/provider/my-states-in-progress-icon.png"}
              className="w-14 h-14 mr-5"
            />
            <div className="border-l w-1 border-gray-400"></div> &nbsp;
            <div>
              <span className="text-xxl block ">{data.target}%</span>
              <span className="text-gray_out_text">Target (Mandated)</span>
            </div>
          </div>
          <div className="flex px-4">
            <img
              src={"/assets/icons/provider/my-states-in-progress-icon.png"}
              className="w-14 h-14 mr-5"
            />
            <div className="border-l w-1 border-gray-400"></div> &nbsp;
            <div>
              <span className="text-xxl block ">{data.current_score}%</span>
              <span className="text-gray_out_text">My Current Score</span>
            </div>
          </div>
          <div className="flex px-4">
            <img
              src={"/assets/icons/provider/my-states-in-progress-icon.png"}
              className="w-14 h-14 mr-5"
            />
            <div className="border-l w-1 border-gray-400"></div> &nbsp;
            <div>
              <span className="text-xxl block ">${data.cost}</span>
              <span className="text-gray_out_text">Cost (Amount Spent)</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyStatesApp;
