import React, { useContext, useEffect, useState } from "react";
import DashboardHoc from "../../DashboardHoc";
import Appointment from "./Appointment";
import AppointmentList from "../widgets/AppointmentList";
import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import AutoCompleteComponent from "../../../patient/AutoComplete";
function Scheduling() {
  const { userDetails } = useContext(AuthContext);
  const today = new Date();

  const [isLoading, setLoading] = useState(true);
  const { getMyAppointments } = useProviderActions();
  const [appointments, setAppointmentList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyAppointments()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setAppointmentList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  const updateData = (date) => {
    setLoading(true);

    getMyAppointments(date)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setAppointmentList(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [openCreateModel, setOpenCreateModel] = React.useState(false);

  const [patientQuery, setPatientQuery] = React.useState("");

  const searchPatient = (e) => {
    setPatientQuery(e.target.value);
  };

  return (
    <DashboardHoc>
      <div className="w-full bg-white px-4 mx-2">
        <div className="text-xxl font-semibold my-2">
          Calendar/Appointment Scheduling
        </div>
        <div className="flex w-full">
          <div className="w-1/4 h-full overflow-y-auto">
            <div className="flex gap-2   items-start w-full flex-col">
              <img
                src="/assets/icons/provider/create-event-button.png"
                alt=""
                height={150}
                width={150}
                onClick={() => setOpenCreateModel(true)}
                className="mb-2 cursor-pointer"
              ></img>

              <DatePicker
                startDate={Date.now()}
                inline
                className=""
                showLeadingZeros={true}
                value={today}
                onChange={(e) => updateData(moment(e).format("YYYY-MM-DD"))}
                minDate={new Date()}
                dateFormat="MMMM, yyyy"
              />

              <div className="">
                {isLoading ? (
                  <>
                    <div className="my-2">
                      <Skeleton variant="rectangular" width={220} height={20} />
                    </div>
                    <div className="my-2">
                      <Skeleton variant="rectangular" width={220} height={20} />
                    </div>
                    <div className="my-2">
                      <Skeleton variant="rectangular" width={220} height={20} />
                    </div>{" "}
                    <div className="my-2">
                      <Skeleton variant="rectangular" width={220} height={20} />
                    </div>
                    <div className="my-2">
                      <Skeleton variant="rectangular" width={220} height={20} />
                    </div>
                  </>
                ) : (
                  <div className="h-36 overflow-y-auto">
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      {appointments.map((listItem, key) => (
                        <div key={key}>
                          <ListItem className="flex w-full items-end">
                            <ListItemText
                              primary={listItem.title}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                      color: "text.primary",
                                      display: "inline",
                                    }}
                                  >
                                    {`${listItem.start_time} - ${listItem.end_time}`}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider component="li" />
                        </div>
                      ))}
                    </List>
                  </div>
                )}
              </div>
              {/* <input
                type="search"
                /> */}
              {/* <AutoCompleteComponent
                className="px-4 py-3 border rounded-lg"
                id="search-patient"
                placeholder="Search for Patient"
                onChange={searchPatient}
                items={[]}
                className="text-3xl placeholder:ml-4 w-full max-w-full rounded-2xl leading-[58px]"
              /> */}
            </div>
          </div>
          <div className="w-full">
            <Appointment
              openCreateModel={openCreateModel}
              setOpenCreateModel={setOpenCreateModel}
            />
          </div>
        </div>
      </div>
    </DashboardHoc>
  );
}

export default Scheduling;
