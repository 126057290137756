import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppSpecialties from "./AppSpecialties";
import AppExperienceAndConsultationHours from "./AppExperienceAndConsultationHours";
import AppProfileForCollaboration from "./AppProfileForCollaboration";
import AppPositionsAndLanguages from "./AppPositionsAndLanguages";
import AppPersonalStatement from "./AppPersonalStatement";
import ProfilePicture from "../../../../../../components/ProfilePicture";
import { AuthContext } from "../../../../../../App";
import useProviderActions from "../../../../../../actions/useProviderActions";
import { ClipLoader } from "react-spinners";
import { Skeleton } from "@mui/material";

function AppProfileSection({ setIsEditing }) {
  const { userDetails } = useContext(AuthContext);

  const [providerDetails, setProviderDetails] = useState(null);
  const { getProviderDetails } = useProviderActions();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProviderDetails(userDetails.user_id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setProviderDetails(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  if (isLoading) {
    return (
      <>
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
      </>
    );
  }
  if (providerDetails) {
    return (
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-2 ">
        <span className="text-xxl font-semibold ">Profile For Patients</span>
        <div className="flex items-center my-3 justify-between">
          <div className="flex items-center gap-2">
            <ProfilePicture picture={providerDetails.user.picture} />{" "}
            <div className="flex flex-col">
              <span className="text-body_font_two font-medium">
                {providerDetails.user.full_name}
              </span>
              <span className="text-gray_out_text">
                APP - Child & Adolescent Psychiatry (Test)
              </span>
              <span className="text-gray_out_text"> License: Pending-test</span>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setIsEditing(true);
              }}
              className={
                "border  py-3 px-5 text-light_tone_one font-bold rounded-xl w-36 submit-btn-bg-color"
              }
            >
              Edit Profile
            </button>
          </div>
        </div>
        <div className="w-full bg-gray_out rounded-md">
          <section className="p-10 max-md:p-2">
            <div className="w-full flex justify-between">
              <div className="w-1/2 text-body_font_two font-medium pr-16 max-md:pr-2">
                <span className="text-color-1">Education & Training</span>

                {providerDetails.education_training.map((x, key) => {
                  return (
                    <div
                      className="pr-16 flex gap-2 text-body_font_three mt-4 max-md:pr-2"
                      key={key}
                    >
                      <img
                        alt=""
                        loading="lazy"
                        src="/assets/images/verified-green-tick.png"
                        className="w-5 h-4"
                      />
                      <span>{x}</span>
                    </div>
                  );
                })}
              </div>
              <div className="w-1/2 text-body_font_two font-medium pl-16 max-md:p-2">
                <span className="text-color-1"> Professional Memberships</span>
                {providerDetails.memberships.map(
                  ({ institution_name }, key) => {
                    return (
                      <div
                        key={key}
                        className="pr-16 flex gap-2 text-body_font_three mt-4 max-md:px-2"
                      >
                        <img
                          alt=""
                          loading="lazy"
                          src="/assets/images/verified-green-tick.png"
                          className="w-5 h-4"
                        />
                        <span>{institution_name}</span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </section>

          <div className="flex-grow border-t border-gray-400 my-4"></div>
          <AppSpecialties />
          <div className="flex-grow border-t border-gray-400"></div>
          {/* <AppExperienceAndConsultationHours
            totalExperience={providerDetails.total_experience}
            consultationHours={providerDetails.consultation_hours}
          /> */}
          <div className="flex-grow border-t border-gray-400"></div>
          <AppPositionsAndLanguages
            languages={providerDetails.languages}
            experiences={providerDetails.experiences}
          />
          <div className="flex-grow border-t border-gray-400"></div>
          <AppPersonalStatement />
        </div>
        <AppProfileForCollaboration />
      </section>
    );
  } else {
    return (
      <>
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
      </>
    );
  }
}

export default AppProfileSection;
