import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { Divider, Skeleton } from "@mui/material";
import moment from "moment";
import useProviderActions from "../../../../actions/useProviderActions";
import { AuthContext } from "../../../../App";
import ProfilePicture from "../../../../components/ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function MyAssignments() {
  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const { getMyPatients } = useProviderActions();
  const [patients, setList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyPatients()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  const title =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit.  Enim atque, suscipit quia";

  const des =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim tque, suscipit quia repellendus, aperiam aspernatur facere, liquid commodi  hic? Facere, consequatur.";
  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96">
      <div className="text-subtitle_one">My Assignment</div>
      <div className="my-4 w-full">
        <div className="flex gap-2  items-center ">
          <div className="flex flex-col gap-2 h-48 overflow-auto w-full">
            <div className="text-gray_out_text  font-normal flex justify-between ">
              <div>Assignment Title</div>
              <div>Provider</div>
            </div>
            {isLoading ? (
              <>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>

                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
              </>
            ) : null}
            <Divider />

            {patients.map((patient, idx) => {
              return (
                <div key={patient.id}>
                  <div className="font-normal my-2">
                    <div className="flex justify-between items-start ">
                      <div className="flex flex-col justify-start items-start  w-2/3 ">
                        {title.length > 45
                          ? [...title].splice(0, 45).join("") + "..."
                          : title}
                        <div className="text-gray_out_text   ">
                          {des.length > 45
                            ? [...des].splice(0, 45).join("") + "..."
                            : des}
                        </div>
                      </div>
                      <div className="flex  items-center gap-2">
                        <ProfilePicture picture={patient.picture} />
                        {patient.name}
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <div className="flex justify-end mb-1">
          <Link
            to="/patient/communication-and-assignment/assignment"
            className="flex justify-end mb-2"
          >
            <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
              View Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyAssignments;
