import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import useUserActions from "../../../../../actions/useUserActions";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import { ProfilePictureUploader } from "../../../../../components/ProfilePictureUploader";
import ProfilePicture from "../../../../../components/ProfilePicture";
import { AuthContext } from "../../../../../App";
import {
  FAITH,
  GENDERS,
  PREFIXES,
  PRO_NOUNS,
  RELATIONSHIP_STATUS,
  SUFFIXES,
} from "../../../../forms/registration-and-verification/utils";

import FormInputComponent from "../../../../forms/registration-and-verification/FormInputComponent";
import FormButtonComponent from "../../../../forms/registration-and-verification/FormButtonComponent";
import ModalError from "../../../../../components/modals/ModalError";
function PersonalDetails({ userDetails }) {
  const { userDetails: user } = useContext(AuthContext);
  const initialData = {};
  if (user && user.category_id && user.category_id > -1) {
    initialData["label"] = user.category_name;
    initialData["value"] = user.category_id;
  }
  const [selectedCategory, setSelectedCategory] = useState(initialData);

  const { update: updatePersonalDetails } = useUserActions();
  // const [userDetails, setUser] = useState(null);
  const [pp, setPp] = useState({ data_url: user.picture ?? "" });
  const [gender, setGender] = useState({});
  const [pronounce, setPronounce] = useState({});
  const [updates, setUpdates] = useState({});
  const [isUpdated, setIsUpdates] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (name, value) => {
    setIsUpdates(true);
    setUpdates({ ...updates, [name]: value });
  };

  const onImageChange = (img) => {
    setIsUpdates(true);
    if (img) {
      setPp({ data_url: img });
    } else {
      setPp({ data_url: "none" });
    }
  };
  const [errorModal, setErrorModal] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const onSubmit = (data) => {
    if (
      selectedCategory === undefined ||
      selectedCategory.value == undefined ||
      selectedCategory.value < 0
    ) {
      setErrorModal(true);
      return;
    }
    const payload = {
      ...data,
      ...updates,
    };

    if (pp.data_url) {
      payload["picture"] = pp.data_url;
    }
    setSaving(true);
    updatePersonalDetails(payload).finally((x) => {
      setSaving(false);
      setIsUpdates(false);
    });
  };
  if (errorModal === true) {
    return (
      <ModalError
        close={() => setErrorModal(false)}
        message={"Please select Provider Category"}
      />
    );
  }
  return userDetails === null ? (
    <ClipLoader
      color={"black"}
      loading={true}
      size={100}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  ) : (
    <div className="h-full bg-white w-full rounded-lg py-10">
      <div className="flex flex-col">
        <span className="block font-semibold text-xxl	pl-10">Personal</span>
        <span className="pl-10">
          Provider ID: {userDetails?.user?.user_id}{" "}
        </span>
        <span className="pl-10"> {userDetails?.user?.category_name} </span>
      </div>

      <div className="py-[1.5px] ml-10 mt-2 bg-gray_out w-11/12" />
      <div className="flex justify-between py-4 px-10 text-gray_out_text items-center">
        <span>Profile Picture</span>

        <span>
          <ProfilePictureUploader
            picture={user.picture}
            onImageChange={onImageChange}
            isDisabled={!isEditing}
          />
        </span>
        {user.picture &&
        pp.data_url === user.picture &&
        pp.data_url !== "none" ? (
          <div className="flex">
            <button
              onClick={() => setPp({ data_url: "none" })}
              className="mx-2 px-2 rounded bg-complementary_accent_three text-gray_out"
            >
              Delete Profile Picture
            </button>
            <img src={user.picture} width={40} />
          </div>
        ) : null}
        <span>
          {!userDetails.picture ? (
            <img src={userDetails.picture}></img>
          ) : (
            <ProfilePicture />
          )}
        </span>
      </div>
      <PersonalDetailsVerification
        onSubmit={onSubmit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleChange={handleChange}
        isCatEditable={initialData.id ? false : true}
        userDetails={userDetails}
        isSaving={isSaving}
        isUpdated={isUpdated}
      />
    </div>
  );
}

export default PersonalDetails;

function PersonalDetailsVerification({
  onSubmit,
  isEditing,
  handleChange,
  userDetails,
  selectedCategory,
  setSelectedCategory,
  isCatEditable,
  isSaving,
  isUpdated,
  setIsEditing,
}) {
  const { user } = userDetails;
  const { getStateByZip } = useUserActions();
  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm();
  if (!userDetails) return;

  // const decryptedFirstName = CryptoJS.AES.decrypt(
  //   userDetails.decrypt ? userDetails.first_name : "",
  //   process.env.REACT_APP_CRYPTO_KEY
  // ).toString();
  // const handleZIPchange = async (zipValue) => {
  //   if (zipValue.length === 5) {
  //     const zipApiResponse = await getStateByZip(zipValue);
  //     // console.log(zipApiResponse);
  //   }
  // };

  const [catData, setCatData] = useState([]);

  const categoryOptions = catData.map((category) => ({
    label: category.category_name,
    value: category.category_id,
  }));

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {})
      .then((res) => res.json())
      .then((data) => {
        setCatData(data);
      });
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-md:mx-10 ">
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  ">
        <div
          className="
        flex gap-4 justify-start max-md:px-2  max-md:flex-col flex-wrap w-full"
        >
          <div className="w-36">
            <div>Prefix</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      " mt-2 h-10  absolute rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                options={PREFIXES}
                id="prefix"
                name="prefix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("prefix", e.value)}
                defaultValue={PREFIXES.find((x) => x.value === user.prefix)}
              />
              {/* <Select
                classNames={{
                  control: () =>
                   ,
                }}
                name="gender"
                defaultValue={GENDERS.find((x) => x.value === user.gender)}
                onChange={(e) => handleChange("gender", e.value)}
                options={GENDERS}
              /> */}
            </div>
          </div>
          {/* <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"prefix"}
            >
              Prefix
            </label>

            <Select
              className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
              options={PREFIXES}
              id="prefix"
              name="prefix"
              placeholder=""
              isDisabled={!isEditing}
              onChange={(e) => handleChange("prefix", e.value)}
              defaultValue={PREFIXES.find((x) => x.value === user.prefix)}
            />
          </div> */}
          <FormInputComponent
            register={register}
            inputFor={"firstName"}
            isDisabled={!isEditing}
            inputName="first_name"
            inputLabel={"First Name"}
            inputType="text"
            className=" w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 py-2  pl-2 "
            inputId="firstName"
            inputAutocomplete="firstName"
            // validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={user.first_name}
          />{" "}
          <FormInputComponent
            isDisabled={!isEditing}
            register={register}
            inputFor={"midName"}
            inputName="middle_name"
            inputLabel={"Middle Name"}
            inputType="text"
            className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 py-2  pl-2 "
            inputId="midName"
            inputAutocomplete="midName"
            // validations={{ required: false }}
            inputPlaceholderP=""
            defaultValue={user.middle_name}
          />{" "}
          <FormInputComponent
            register={register}
            inputFor={"lastName"}
            inputName="last_name"
            inputLabel={"Last Name"}
            inputType="text"
            className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 py-2  pl-2 "
            inputId="lastName"
            inputAutocomplete="lastName"
            // validations={{ required: false }}
            defaultValue={user.last_name}
            inputPlaceholder=""
            isDisabled={!isEditing}
          />
          {/* <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"suffix"}
            >
              
            </label>
          
          </div> */}
          <div className="w-36">
            <div>Suffix</div>
            <div>
              <Select
                options={SUFFIXES}
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                id="suffix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("suffix", e.value)}
                defaultValue={SUFFIXES.find((x) => x.value === user.suffix)}
              />
              {/* <Select
                classNames={{
                  control: () =>
                   ,
                }}
                name="gender"
                defaultValue={GENDERS.find((x) => x.value === user.gender)}
                onChange={(e) => handleChange("gender", e.value)}
                options={GENDERS}
              /> */}
            </div>
          </div>
          <div className="w-72">
            <div>Gender</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="gender"
                isDisabled={!isEditing}
                defaultValue={GENDERS.find((x) => x.value === user.gender)}
                onChange={(e) => handleChange("gender", e.value)}
                options={GENDERS}
              />
            </div>
          </div>
          <FormInputComponent
            register={register}
            inputFor={"birthdate"}
            inputName="birthdate"
            inputLabel={"Date of Birth"}
            inputType="date"
            isDisabled={!isEditing}
            className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 py-2  pl-2 "
            inputId="birthdate"
            inputAutocomplete="birthdate"
            inputPlaceholderP=""
            onChange={(e) => handleChange("birthdate", e.value)}
            defaultValue={user.birthdate}
          />
          <div className="w-72">
            <div>Relationship Status</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="pronoun"
                defaultValue={RELATIONSHIP_STATUS.find(
                  (x) => x.value === user.relationship_status
                )}
                onChange={(e) => handleChange("relationship_status", e.value)}
                options={RELATIONSHIP_STATUS}
                isDisabled={!isEditing}
              />
            </div>
          </div>
          <div className="w-72">
            <div>Pronoun</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="pronoun"
                defaultValue={PRO_NOUNS.find((x) => x.value === user.pronoun)}
                onChange={(e) => handleChange("pronoun", e.value)}
                options={PRO_NOUNS}
                isDisabled={!isEditing}
              />
            </div>
          </div>
          <div className="w-72">
            <div>Faith</div>
            <div>
              <Select
                classNames={{
                  control: () =>
                    classNames(
                      {},
                      "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize"
                    ),
                }}
                name="faith"
                defaultValue={FAITH.find((x) => x.value === user.faith)}
                onChange={(e) => handleChange("faith", e.value)}
                options={FAITH}
                isDisabled={!isEditing}
              />
            </div>
          </div>{" "}
        </div>

        {/* <Select
          classNames={{
            control: (state) =>
              classNames(
                {},
                "w-60 h-10 rounded-xl select-box-upload-doc  max-md:w-80 my-4"
              ),
          }}
          placeholder="Provide Category"
          options={[selectedCategory]}
          // options={categoryOptions}
          value={selectedCategory}
          onChange={() => {}}
        /> */}

        <div className="mt-2 flex gap-3 max-md:px-2 ">
          <FormButtonComponent
            ButtonType="submit"
            isDisabled={isSaving || !isEditing}
            className={classNames(
              { "text-light_tone_one  submit-btn-bg-color": isEditing },
              "text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 "
            )}
            ButtonValue={isSaving ? "Saving..." : "Save"}
          ></FormButtonComponent>
          <FormButtonComponent
            ButtonType="button"
            onClick={() => setIsEditing(!isEditing)}
            isDisabled={isSaving}
            className={classNames(
              { "text-light_tone_one  submit-btn-bg-color": !isEditing },
              {
                "  bg-complementary_accent_three": isEditing,
              },

              "text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 "
            )}
            ButtonValue={isEditing ? "Cancel" : "Edit"}
          ></FormButtonComponent>
        </div>
      </div>
    </form>
  );
}
