import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../../App";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { TrashIcon } from "@heroicons/react/20/solid";

function LanguageSpoken() {
  const [isAdding, setAdding] = useState(false);
  const [langs, setLangs] = useState([]);
  const { saveLanguages, getSpokenLanguages, deleteLang } =
    useProviderActions();
  const { userDetails } = useContext(AuthContext);
  const [isEdited, setIsEdited] = useState(false);
  const getData = () =>
    getSpokenLanguages(userDetails.user_id).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setLangs(res.data);
        }
      }
    });
  useEffect(() => {
    if (userDetails && userDetails.user_id) getData();
  }, []);
  const handleChange = (value, idx) => {
    if (value) {
      setIsEdited(true);
      const temp = [...langs];
      temp[idx] = value;
      setLangs([...temp]);
    }
  };
  const [saving, setSaving] = useState(false);
  const removeItem = (id) => {
    setSaving(true);
    deleteLang(id).then(() => {
      getData();
    });
  };

  const onSave = (newVal) => {
    setSaving(true);
    saveLanguages({
      user_id: userDetails.user_id,
      languages: [...langs, { language: newVal }],
    })
      .then((x) => {
        setIsEdited(false);
        getData();
      })
      .finally((x) => {
        setSaving(false);
      });
  };

  return (
    <div className="my-4  px-10 max-md:px-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Language Spoken
        </div>
        <div className=" p-2 rounded-md text-gray_out_text my-2">
          <div className="my-4 flex gap-5 items-center flex-wrap">
            {langs.map((item, idx) => (
              <div key={item.id} className="relative">
                <input
                  // onChange={(e) => handleChange(e.target.value, idx)}
                  placeholder="Enter Language"
                  className=" border p-3 w-72 rounded-md  bg-gray_out my-2 max-md:w-80 "
                  defaultValue={item.language}
                  disabled
                />
                <button className="w-5  absolute right-1 top-5">
                  <TrashIcon
                    color="error"
                    onClick={() => removeItem(item.id)}
                  />
                </button>
              </div>
            ))}
            {isAdding ? (
              <input
                placeholder="Enter New Language"
                onBlur={(e) => {
                  if (e.target.value) {
                    setAdding(false);
                    onSave(e.target.value);
                  }
                }}
                onChange={() => {
                  if (!isEdited) setIsEdited(true);
                }}
                className="w-72 border p-3 rounded-md  bg-gray_out max-md:w-80"
              />
            ) : (
              <div className="w-72 max-md:w-80">
                <img
                  className="cursor-pointer"
                  onClick={() => setAdding(true)}
                  src="/assets/icons/provider/add-icon.png"
                ></img>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default LanguageSpoken;
