import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { fakeMessage } from "../../../common/messageing/components/utils";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { CreateOutlined } from "@mui/icons-material";

function MessageWidget() {
  return (
    <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-full relative">
      <div className="text-subtitle_one">Communication Centre</div>
      <div className="my-4">
        <div className="flex gap-2  items-center max-md:flex-col">
          <div className="flex justify-between w-full">
            <Link to="messaging">
              <button className="px-2 py-1 border max-md:w-36 w-52   rounded-lg bg-light_tone_one">
                0 Unread
              </button>
            </Link>
            {/* <Link to="messaging"> */}
            {/* <button className="px-2 py-1 border max-md:w-36 w-52 rounded-lg bg-light_tone_one">
              <CreateOutlined />
              <span className="px-2">Compose</span>
            </button> */}
            {/* </Link> */}
          </div>
        </div>
        {/* <Link to="messaging"> */}
        <List
          dense
          sx={{
            width: "100%",
            maxHeight: "200px",
            bgcolor: "background.paper",
            overflow: "auto",
          }}
        >
          <ListItem className="mb-30 border-b">No New message</ListItem>
        </List>
        {/* </Link> */}
        <div className="flex justify-end mb-1">
          {/* <Link to="messaging"> */}
          <button
            className="
            submit-btn-bg-color-test-todo
             text-light_tone_one p-4 px-10 rounded-lg"
          >
            View Details
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}

export default MessageWidget;
