import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HowToRegister from "./HowToRegister";
import MessageFromCEO from "./MessageFromCEO";
import CarouselComponent from "./Carousel";
import { cityNames, formatResult } from "./data";
import AutoCompleteComponent from "./AutoComplete";
import Testimonials from "../provider/Testimonials";
import WhyUs from "./WhyUs";
import { PersistQueryLink } from "../../PersistQueryLink";
function Home() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && observer) {
          const counters = document.querySelectorAll(".counter-entries");

          counters.forEach((counter) => {
            let initial_count = 0;
            const final_count = counter.dataset.count;

            let counting = setInterval(updateCounting, 1);

            function updateCounting() {
              if (initial_count < 1000) {
                initial_count += 1;
                counter.innerText = initial_count;
              }

              if (initial_count >= 1000) {
                initial_count += 100;
                counter.innerText = (initial_count / 1000).toFixed(1) + "K+";
              }

              if (initial_count >= 10000) {
                initial_count += 5000;
              }

              if (initial_count >= 1000000) {
                initial_count += 500000;
                counter.innerText = (initial_count / 1000000).toFixed(1) + "M+";
              }

              if (initial_count >= final_count) {
                clearInterval(counting);
              }
            }
          });
          // observer?.unobserve(componentRef.current);
        }
      },
      { threshold: 0.5 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const componentRef = useRef(null);
  return (
    <div>
      <div className="flex flex-col relative items-baseline  w-full max-md:max-w-full">
        <div className="flex max-md:flex-col items-baseline justify-start ">
          <CarouselComponent />
          <div className="absolute top-20 mt-4 px-8 capitalize text-6xl font-semibold leading-[78px] max-md:top-0 max-md:relative">
            Taking care of all types of mental health issues. One destination.
          </div>
          <div className="absolute max-md:relative  flex mt-64 lg:ml-8 items-center lg:w-44-rem max-md:flex-col max-md:mt-8 ">
            <AutoCompleteComponent
              items={cityNames}
              className="text-3xl placeholder:ml-4 w-full max-w-full rounded-2xl leading-[58px]"
              placeholder="Tell us your location."
              onC
            />
            <PersistQueryLink to="/search-provider">
              <div className="justify-start self-stretch px-8 py-3.5 my-auto font-bold text-white whitespace-nowrap bg-blue-900 rounded-2xl max-md:px-5 max-md:mt-8 max-md:w-64">
                <div className="flex gap-4">
                  <img
                    loading="lazy"
                    src="/assets/images/search-glass-icon.png"
                    width={20}
                    height={20}
                  />
                  Search for Provider
                </div>
              </div>
            </PersistQueryLink>
          </div>
        </div>
        <div className="self-stretch mt-12 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div className="flex flex-col w-[41%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src="/assets/images/patient-dashboard-img-2.png"
                width={450}
                className="grow relative aspect-[1.02] max-md:max-w-full"
              />
            </div>
            <div className="flex  absolute max-md:relative flex-col ml-96 w-[59%] max-md:ml-0 max-md:w-full">
              <div className="flex p-10 flex-col mt-16 w-full text-xl font-bold text-black bg-white rounded-3xl max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <div className="mt-3.5 text-3xl tracking-wider uppercase max-md:max-w-full">
                  Start your journey towards mental wellbeing.
                </div>
                <div className="mt-4 font-light leading-7 max-md:max-w-full">
                  Our fully automated platform with the best of providers,
                  scheduling service, flexible payment option and others
                  simplify the process of accessing quality mental healthcare on
                  time, from anywhere. No third-party involvement. No worries.{" "}
                </div>
                <br />
                <Link to="/login">
                  <div className="justify-center self-stretch w-[35%] px-10 py-3.5 my-auto font-bold text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5 max-md:w-64">
                    Register as a patient
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={componentRef}
          className="flex w-full mt-12 gap-56 max-md:flex-col max-md:gap-14 max-md:items-center justify-center"
        >
          <div className="flex flex-col gap-3.5 max-md:items-center ">
            <div className="text-5xl font-bold  ">
              <span className="counter-entries" data-count="15">
                15
              </span>{" "}
              %
            </div>
            <div> 5 star reviews</div>
          </div>
          <div className="flex flex-col gap-3.5 max-md:items-center">
            <div className="text-5xl font-bold  ">
              {"<"}
              <span className="counter-entries" data-count="15">
                15
              </span>{" "}
              mins
            </div>
            <div>Average wait time for first appointment.</div>
          </div>
          <div className="flex flex-col gap-3.5 max-md:items-center">
            <div className="text-5xl font-bold  ">
              <span className="counter-entries" data-count="108">
                108
              </span>{" "}
              years{" "}
            </div>
            <div>Combined experience of Providers</div>
          </div>
        </div>
      </div>
      <HowToRegister />
      <WhyUs />
      <div className="max-md:p-14 max-md:mt-28 lg:mt-56">
        <MessageFromCEO />
      </div>
      {/* <Testimonials /> */}
    </div>
  );
}

export default Home;
