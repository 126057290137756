import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import Rating from "@mui/material/Rating";
import useProviderActions from "../../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";
import ProfilePicture from "../../../../components/ProfilePicture";

function ReviewManagementWidget() {
  const [reviews, setReviews] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const { getReviews } = useProviderActions();

  React.useEffect(() => {
    getReviews()
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setReviews(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96 relative">
      <div className="text-subtitle_one">Review Management</div>
      {isLoading ? (
        <>
          <div className="my-2">
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
          <div className="my-2">
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
          <div className="my-2">
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
          <div className="my-2">
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
          <div className="my-2">
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </div>
        </>
      ) : (
        <>
          <Link
            className="my-2"
            to="/provider/performance-and-insights/review-management"
          >
            <button className="bg-light_tone_one w-48 text-light_tone_one  py-3 rounded-lg text-black">
              <span>
                {reviews.filter((x) => x.status == "pending").length} | Pending
                Review
              </span>
            </button>
          </Link>
          <List className="h-72 overflow-auto">
            {reviews.map(
              (
                {
                  created_at,
                  patient_name,
                  patient_picture,
                  rating,
                  review,
                  status,
                },
                x
              ) => (
                <Link to="/provider/performance-and-insights/review-management">
                  <div key={x}>
                    <ListItem>
                      <ListItemButton>
                        <ListItemIcon>
                          <ProfilePicture
                            picture={patient_picture}
                            name={patient_name}
                            width="35px"
                            height="35px"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <span className=" flex flex-col">
                            <span className="text-body_font_three font-semibold">
                              {patient_name}
                            </span>
                            <span className="text-body_font_three font-semibold">
                              <Rating
                                name="read-only"
                                value={rating}
                                readOnly
                              />
                            </span>
                            <span className="text-body_font_three ">
                              {review}
                            </span>
                          </span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </div>
                </Link>
              )
            )}
          </List>

          <img
            className="w-full h-0.5 my-5"
            src="/assets/images/login-img-3-1.png"
          ></img>
          <div className="absolute right-3 bottom-1">
            <div className="flex justify-end my-1">
              <Link
                to="/provider/performance-and-insights/review-management"
                className="flex justify-end mb-2"
              >
                <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                  View Details
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ReviewManagementWidget;
