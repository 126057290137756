import { createBrowserRouter } from "react-router-dom";
import { About, ProviderHomePage, LoginPage, ProviderDashboard } from "./index";
import PatientHome from "../containers/patient/PatientHome";
import ProviderRegistrationForm from "../containers/forms/registration-and-verification/ProviderRegistrationForm";
import Activate from "../containers/common/ActivePage";
import PersonalProfile from "../containers/provider/dashboard-main-pages/profile/personal-profile/PersonalProfile";
import ProfileManagement from "../containers/provider/dashboard-main-pages/profile/personal-profile/ProfileManagement";
import SchedulingAndCommunication from "../containers/provider/dashboard-main-pages/scheduling-and-communication/SchedulingAndCommunication";
import CareAndCollaboration from "../containers/provider/dashboard-main-pages/collaborator/CareAndCollaboration";
import MedicalProfile from "../containers/provider/dashboard-main-pages/profile/medical-profile/MedicalProfile";
import ProviderMessaging from "../containers/provider/dashboard-main-pages/scheduling-and-communication/ProviderMessaging";
import Appointment from "../containers/provider/dashboard-main-pages/scheduling-and-communication/Appointment";
import Scheduling from "../containers/provider/dashboard-main-pages/scheduling-and-communication/Scheduling";
import MyPatientList from "../containers/provider/dashboard-main-pages/collaborator/MyPatientList";
import CollaborationMain from "../containers/provider/dashboard-main-pages/collaborator/CollaborationMain";
import PerformanceAndInsights from "../containers/provider/dashboard-main-pages/p-and-i/PerformanceAndInsights";
import ReviewManagement from "../containers/provider/dashboard-main-pages/p-and-i/ReviewManagement";
import CSAForm from "../containers/forms/registration-and-verification/CSAForm";
import { patientAuthRoutes, patientUnAuthRoutes } from "./patientRoutes";
const commonRoutes = [
  {
    path: "/about-us",
    element: (
      <Activate>
        <About />
      </Activate>
    ),
  },
  {
    path: "/home/provider",
    element: (
      <Activate>
        <ProviderHomePage />
      </Activate>
    ),
  },
  {
    path: "/home/patient",
    element: (
      <Activate>
        <PatientHome />
      </Activate>
    ),
  },

  {
    path: "*",
    element: (
      <Activate>
        <Activate />
      </Activate>
    ),
  },
];

export const providerRoutes = createBrowserRouter([
  {
    path: "registration/and/verification",
    element: (
      <Activate>
        <ProviderRegistrationForm />
      </Activate>
    ),
  },
  {
    path: "registration/and/verification/csa",
    element: (
      <Activate>
        <CSAForm />
      </Activate>
    ),
  },
  {
    path: "provider/dashboard",
    element: (
      <Activate>
        <ProviderDashboard />
      </Activate>
    ),
  },
  {
    path: "provider/profile-management/personal-profile",
    element: (
      <Activate>
        <PersonalProfile />
      </Activate>
    ),
  },
  {
    path: "provider/profile-management/medical-profile",
    element: (
      <Activate>
        <MedicalProfile />
      </Activate>
    ),
  },
  {
    path: "provider/profile-management",
    element: (
      <Activate>
        <ProfileManagement />
      </Activate>
    ),
  },
  {
    path: "provider/scheduling-communication",
    element: (
      <Activate>
        <SchedulingAndCommunication />
      </Activate>
    ),
  },
  {
    path: "provider/scheduling-communication/messaging",
    element: (
      <Activate>
        <ProviderMessaging />
      </Activate>
    ),
  },
  {
    path: "provider/scheduling-communication/calender",
    element: (
      <Activate>
        <Scheduling />
      </Activate>
    ),
  },

  {
    path: "provider/care-collaboration",
    element: (
      <Activate>
        <CareAndCollaboration />
      </Activate>
    ),
  },
  {
    path: "provider/care-collaboration/patient-list",
    element: (
      <Activate>
        <MyPatientList />
      </Activate>
    ),
  },

  {
    path: "provider/care-collaboration/details",
    element: (
      <Activate>
        <CollaborationMain />
      </Activate>
    ),
  },
  {
    path: "provider/scheduling-communication",
    element: (
      <Activate>
        <SchedulingAndCommunication />
      </Activate>
    ),
  },

  {
    path: "provider/dashboard",
    element: (
      <Activate>
        <ProviderDashboard />
      </Activate>
    ),
  },
  {
    path: "provider/performance-and-insights",
    element: (
      <Activate>
        <PerformanceAndInsights />
      </Activate>
    ),
  },
  {
    path: "provider/performance-and-insights/review-management",
    element: (
      <Activate>
        <ReviewManagement />
      </Activate>
    ),
  },

  {
    path: "*",
    element: (
      <Activate>
        <Activate />
      </Activate>
    ),
  },
]);

export const unAuthorizedRoutes = createBrowserRouter([
  {
    path: "/login/:userType",
    element: (
      <Activate>
        <LoginPage />
      </Activate>
    ),
  },
  {
    path: "/register/:userType",
    element: (
      <Activate>
        <LoginPage />
      </Activate>
    ),
  },
  {
    path: "/sso/:userType",
    element: (
      <Activate>
        <LoginPage />
      </Activate>
    ),
  },
  {
    path: "/home/provider",
    element: (
      <Activate>
        <ProviderHomePage />
      </Activate>
    ),
  },
  {
    path: "*",
    element: (
      <Activate>
        <Activate />
      </Activate>
    ),
  },
  ...patientUnAuthRoutes,
  ...commonRoutes,
]);

export const patientRoutes = patientAuthRoutes;
