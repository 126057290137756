import React, { useContext, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputComponent from "../InputComponent";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../../App";
import classNames from "classnames";
import useAuth from "../../../actions/useAuth";
import { Divider } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

function SignupPageTwo({ heading, subHeading = "", setPageType }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const { userType } = useParams();
  const { getGoogleUserDetails } = useAuth();
  const { signupByEmail } = useAuth();
  const [error, setError] = useState("");
  const onSubmit = async (data) => {
    const res = await signupByEmail(data, userType);
    setError(res.messages);
  };

  const responseFacebook = (response) => {
    // console.log(response);
  };
  const authState = useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);

  const loginByGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getGoogleUserDetails(tokenResponse.access_token, false, userType);
      authState.setAuth(true);
    },
  });

  return (
    <>
      <div className="py-6 px-16 max-md:p-2">
        <div className="text-2xl">{heading}</div>
        <div className="text-body_font_three flex ">
          {subHeading} &nbsp;
          <div
            onClick={() => setPageType("login")}
            className=" text-blue-600	 cursor-pointer"
          >
            Login
          </div>
        </div>
        <div className="flex flex-col gap-6  mt-4">
          <button
            onClick={() => loginByGoogle()}
            className="text-button_font p-4 bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/google-login-img.png"></img>
            Signup with Google
          </button>
        </div>

        {/* <div className="flex gap-10 mt-4">
          <button
            onClick={() => loginByGoogle()}
            className="text-button_font px-14 py-4 max-md:px-5  bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/google-login-img.png"></img>
          </button>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="z-20 text-button_font px-14 py-4 max-md:px-5 
                hover:border-transparent  border rounded-md text-light_tone_one 
                fb-btn-colors flex justify-center gap-3 items-center"
              >
                <img src="/assets/images/facebook-login-img.png"></img>
              </button>
            )}
          />

          <button
            disabled={true}
            className="text-button_font px-14 py-4 max-md:px-5 hover:border-transparent  rounded-md text-light_tone_one border bg-dark_tone flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/apple-login-img.png"></img>
          </button>
        </div> */}
        <div className="my-2">
          <Divider>
            <span className="mx-4 text-gray-500">OR</span>
          </Divider>
        </div>
        {userType === "patient" ? (
          <div>
            Register as Patient To Register as Provider, &nbsp;
            <Link className="text-mid_tone_two" to="/register/provider">
              Click here
            </Link>
          </div>
        ) : (
          <div>
            Register as Provider To Register as Patient, &nbsp;
            <Link className="text-mid_tone_two" to="/register/patient">
              Click here
            </Link>
          </div>
        )}
        <div>
          {error ? <span className="text-error ">{error}</span> : null}

          <form className=" flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 ">
              <InputComponent
                inputFor={"email"}
                inputName="email"
                inputLabel={"Email"}
                inputType="text"
                inputId="email"
                inputAutocomplete="email"
                register={register}
                validations={{
                  required: { value: true, message: "Email Required" },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                inputPlaceholder="Email ID"
                errors={errors.email}
              />
              <br />
              <div className="relative">
                <InputComponent
                  inputFor={"password"}
                  inputName="password"
                  inputLabel={"Password"}
                  inputType={showPassword ? "text" : "password"}
                  inputId="password"
                  inputAutocomplete=""
                  register={register}
                  validations={{
                    required: { value: true, message: "Password Required" },
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
                      message:
                        "Password must be at least 8 characters and include at least one letter, one number and one special character",
                    },
                  }}
                  inputPlaceholder="Set Your Password Here"
                />
                <div
                  className="absolute cursor-pointer"
                  style={{
                    top:
                      errors.password?.message === "Password Required"
                        ? "40%"
                        : errors.password
                        ? "35%"
                        : "50%",
                    right: "4%",
                  }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
                {errors.password && (
                  <span className="text-error">{errors.password.message}</span>
                )}
              </div>
              <br />
              <div className="text-body_font_three">
                Create a strong password with a mix of letters, numbers and
                symbols
              </div>
              <br />

              <button
                type="submit"
                disabled={errors?.name & errors?.password || isSubmitting}
                className={classNames(
                  {
                    "submit-btn-bg-color": !errors.email && !errors.password,
                  },
                  "text-button_font p-4  hover:border-transparent  border rounded-md w-full text-light_tone_one bg-mid_tone_two cursor-pointer"
                )}
                value="Continue"
              >
                {isSubmitting ? (
                  <>
                    <span>Creating Profile... &nbsp;</span>
                    <ClipLoader
                      color={"#ffffff"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignupPageTwo;
