import React from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../../components/ProfilePicture";
import dummyproviderData from "./dummyproviderData.json";
import Header from "../common/Header";
import Footer from "../main/components/Footer";
import { Details } from "../provider/dashboard-main-pages/profile/medical-profile/md-users/MdProfileSection";
import AppointmentPicker from "./AppointmentPicker";
function ProviderFullDetails() {
  const data = dummyproviderData;
  return (
    <div>
      <div className="flex flex-col items-center  bg-zinc-100">
        <div className="flex flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
          <Header isPatent={true} />
          <div className="p-10 max-md:p-2">
            <div className="text-2xl my-4 text-color-1 font-semibold ">
              Provider Profile
            </div>
            <div className=" bg-white  rounded-lg shadow overflow-y-auto mx-4 py-4 max-md:w-80 px-8 max-md:px-2">
              <div className="">
                <div className="flex gap-2 items-center">
                  {data.picture ? (
                    <img
                      src={data.picture}
                      width="64px"
                      height="64px"
                      className="rounded-full"
                    />
                  ) : (
                    <ProfilePicture
                      name={data.first_name}
                      width="64px"
                      height="64px"
                    />
                  )}
                  <div className="py-2 w-4/5">
                    <span className="text-body_font_two font-medium ">
                      {data.full_name}
                    </span>

                    <span className="block text-gray_out_text">
                      {data.category_name}
                    </span>
                  </div>
                </div>
                <Details providerDetails={data.medical_profile} />
              </div>
              <AppointmentPicker />
            </div>
          </div>
        </div>
      </div>
      <Footer isPatent={true} />
    </div>
  );
}

export default ProviderFullDetails;
