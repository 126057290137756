import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ModalComponent from "../../../../../components/modals/ModalComponent";
import useUserActions from "../../../../../actions/useUserActions";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 2,
// };

export default function ChangePassword({ onClose, isOpen }) {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const isDisabled =
    !currentPassword ||
    !confirmPassword ||
    !newPassword ||
    confirmPassword !== newPassword ||
    !regex.test(newPassword);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordC, setShowPasswordC] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { changePassword } = useUserActions();
  const handleUpdate = () => {
    if (isDisabled) {
      return null;
    }

    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    changePassword(payload);
  };

  const body = (
    <div className="w-[512px]">
      <div className="text-xl my-2">Change Password</div>
      <div className="w-full my-2">
        <Divider />
      </div>
      <div className=" p-2 w-full ">
        <div className="flex flex-col gap-3">
          <div className="relative">
            <label
              htmlFor="Current Password"
              className="block  pl-2 text-color-1 mb-1"
            >
              Current Password
            </label>
            <input
              className={`w-full                     bg-transparent   text-gray-900  h-12                   p-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500`}
              placeholder=" Current Password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              id="Current Password"
              type={showPasswordC ? "text" : "password"}
            />
            <div
              className="absolute cursor-pointer"
              style={{
                top: "50%",
                right: "4%",
              }}
              onClick={() => {
                setShowPasswordC(!showPasswordC);
              }}
            >
              {!showPasswordC ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <div className="relative">
            <label
              htmlFor="Current Password"
              className="block  pl-2 text-color-1 mb-1"
            >
              New Password
            </label>
            <input
              className={`w-full                     bg-transparent   text-gray-900  h-12                   p-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500`}
              onChange={(e) => setNewPassword(e.target.value)}
              id={"new_password"}
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              label="Password"
            />
            <div
              className="absolute cursor-pointer"
              style={{
                top: "50%",
                right: "4%",
              }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {!showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>

          <div className="relative">
            <label
              htmlFor="Current Password"
              className="block  pl-2 text-color-1 mb-1"
            >
              Confirm Password
            </label>
            <input
              className={`w-full                     bg-transparent   text-gray-900  h-12                   p-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500`}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id={"confirm_password"}
              type={showPassword ? "text" : "password"}
              placeholder={"Confirm Password"}
            />
            <div
              className="absolute cursor-pointer"
              style={{
                top: "50%",
                right: "4%",
              }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {!showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <div className="w-full my-2">
            <Divider />
          </div>
          {newPassword && !regex.test(newPassword) ? (
            <span className="text-error">{`Password must be at least 8 characters and include at least one letter, one number and one special character`}</span>
          ) : confirmPassword !== newPassword ? (
            <span className="text-error">{`
          Confirm password not matched.
          `}</span>
          ) : (
            !currentPassword && (
              <span className="text-error">{`
            Current password Required.
            `}</span>
            )
          )}
          <div className="text-body_font_three">
            Create a strong password with a mix of letters, numbers and symbols
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleUpdate}
              className={classNames(
                {
                  "cursor-not-allowed border": isDisabled,
                  "cursor-pointer submit-btn-bg-color": !isDisabled,
                },
                " btn text-light_tone_one py-3 px-5 rounded-xl w-36 max-md:w-full"
              )}
            >
              Update
            </button>
            <button
              onClick={onClose}
              className="bg-complementary_accent_three btn  py-3 px-5 rounded-xl w-36 max-md:w-full
            "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <ModalComponent isOpen={isOpen} body={body} />
    </div>
  );
}
