import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import SearchNewCollaboratorsDetails from "./SearchNewCollaboratorsDetails";
import classNames from "classnames";
import ProfilePicture from "../../../../components/ProfilePicture";
import useCollabActions from "../../../../actions/useCollabActions";

function SearchNewCollaborators() {
  const [expandedRow, setExpandedRow] = useState(-1);
  const [isOpenNotes, setNotesModal] = useState(false);
  const [data, setData] = React.useState([]);

  const [qs, setQs] = React.useState("");
  const { searchProviderForCollab } = useCollabActions();
  // useEffect(() => {
  const handleSearch = () => {
    searchProviderForCollab(qs).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setData(res.data);
        }
      }
    });
  };
  // },
  return (
    <div className="bg-white w-full ease-in duration-300  rounded-lg shadow-lg  ">
      <div className="text-subtitle_one px-4 py-4 flex  w-full justify-between max-md:flex-col">
        <div>Search For Collaborator</div>
        <div className="border rounded gap-1 flex">
          {/* <div className=" p-2 flex gap-1 items-center rounded-lg shadow-sm"> */}
          <input
            type="search"
            onChange={(e) => {
              setQs(e.target.value);
              if (data && data.length) {
                setData([]);
              }
            }}
            className="  p-1 "
            placeholder="Search by location"
          />
          <button
            onClick={handleSearch}
            disabled={!qs}
            className={classNames(
              {
                "submit-btn-bg-color": qs,
              },
              " text-white px-4 py-2 rounded max-md:p-1"
            )}
          >
            <FontAwesomeIcon
              icon={faSearch}
              // className="h-2 w-2 text-gray-400 border  rounded-full "
              aria-hidden="true"
            />
            Search
          </button>
          {/* </div> */}
        </div>
      </div>{" "}
      <div className="relative overflow-x-auto max-md:max-w-80">
        {data && data.length ? (
          <table
            className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400
        
        "
          >
            <thead
              className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400
          
          "
            >
              <tr>
                <th scope="col" className="px-6 py-3">
                  Collaborator
                </th>
                <th scope="col" className="px-6 py-3">
                  Specialties
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate per note
                </th>
              </tr>
            </thead>
            <tbody className="relative">
              {data.map(
                (
                  { full_name, picture, city, collaboration_rate, first_name },
                  index
                ) => (
                  <React.Fragment key={index}>
                    <tr
                      className={classNames(
                        { "border-b": index !== expandedRow },
                        "bg-white dark:bg-gray-800 dark:border-gray-700"
                      )}
                    >
                      <td className="px-6 py-4 flex items-center gap-2">
                        {picture ? (
                          <img src={picture} width="35px" height="35px" />
                        ) : (
                          <ProfilePicture
                            name={first_name}
                            width="35px"
                            height="35px"
                          />
                        )}
                        {full_name}
                      </td>
                      <td className="px-6 py-4"></td>
                      <td className="px-6 py-4">{city}</td>
                      <td className="px-6 py-4">{collaboration_rate}</td>
                      <td
                        className="px-6 py-4 cursor-pointer"
                        onClick={() =>
                          expandedRow === index
                            ? setExpandedRow(-1)
                            : setExpandedRow(index)
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            expandedRow === index ? faChevronUp : faChevronDown
                          }
                          className="h-4 w-4 text-gray-400  rounded-full"
                          aria-hidden="true"
                        />
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr className="border-b">
                        <td colSpan="6">
                          <SearchNewCollaboratorsDetails
                            data={data[expandedRow]}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className="flex py-10 justify-center w-full mt-10">
            No data, Please Search Something
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchNewCollaborators;
