import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DashboardHoc from "../../DashboardHoc";
import { Link } from "react-router-dom";
import ProfilePicture from "../../../../components/ProfilePicture";
import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export default function MyPatientList() {
  const { userDetails } = React.useContext(AuthContext);

  const [isLoading, setLoading] = React.useState(true);
  const { getMyPatients } = useProviderActions();
  const [patients, setList] = React.useState([]);
  React.useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyPatients()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  return (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">My Patient List</div>
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        {isLoading ? (
          <>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </>
        ) : (
          <ResTable patients={patients} />
        )}
      </div>
    </DashboardHoc>
  );
}

const ResTable = ({ patients }) => {
  const theme = useTheme(); // Automatically gets the theme or creates one
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="responsive table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Name</TableCell>
            <TableCell component="th" align="justify">
              Description
            </TableCell>
            <TableCell component="th" align="justify">
              Last Appointment
            </TableCell>
            <TableCell component="th" align="justify">
              Next Appointment
            </TableCell>
            <TableCell component="th" align="justify">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <div className="flex items-baseline gap-2">
                  <ProfilePicture picture={row.picture} />
                  {row.name}
                </div>
              </TableCell>
              <TableCell align="justify">
                <div className="w-96">{row.description}</div>
              </TableCell>
              <TableCell align="justify">{row.last_appointment}</TableCell>
              <TableCell align="justify">{row.next_appointment_date}</TableCell>
              <TableCell className="cursor-pointer" align="justify">
                <img
                  src={"/assets/icons/provider/new-email.png"}
                  className="w-4 h-4 mr-5"
                  alt="Action Icon"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
