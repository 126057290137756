import { Link, useLocation } from "react-router-dom";

function PersistQueryLink({ to, children, ...props }) {
  const location = useLocation();

  const getLinkWithQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.toString();
    return queryString ? `${to}?${queryString}` : to;
  };

  return (
    <Link to={getLinkWithQueryParams()} {...props}>
      {children}
    </Link>
  );
}
export { PersistQueryLink };
