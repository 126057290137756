import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import CollaboratorDetailsView from "./CollaboratorDetailsView";
import NotesModal from "./NotesModal";
import useCollabActions from "../../../../actions/useCollabActions";
import { Skeleton } from "@mui/material";
import ModalComponent from "../../../../components/modals/ModalComponent";

function CollaboratorListApp() {
  const [expandedRow, setExpandedRow] = useState(-1);
  const [isOpenNotes, setNotesModal] = useState(false);

  const [data, setData] = React.useState([]);

  const [qs, setQs] = React.useState("");

  const [loading, setLoading] = React.useState(true);

  const { getExitingCollabs, terminateCollab } = useCollabActions();
  const getData = () => {
    setLoading(true);

    getExitingCollabs()
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setData(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [terminatingId, setTerminatingId] = useState(null);

  const terminate = () => {
    setLoading(true);
    terminateCollab(terminatingId).then(() => {
      getData();
      setTerminatingId(null);
    });
  };
  React.useEffect(() => {
    getData();
  }, []);

  const [isTerminateModelOpen, setTerminateModelOpen] = useState(false);

  if (loading) {
    return (
      <div className="bg-white w-full ease-in duration-300  rounded-lg shadow-lg">
        <div className="text-subtitle_one px-4 py-4">Collaborator</div>
        <div className="relative overflow-x-auto max-md:max-w-80 px-10">
          <Skeleton width={"100%"} height={70} />
          <Skeleton width={"100%"} height={70} />
          <Skeleton width={"100%"} height={70} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white w-full ease-in duration-300  rounded-lg shadow-lg">
      <NotesModal isOpen={isOpenNotes} onClose={() => setNotesModal(false)} />
      <ModalComponent
        isOpen={isTerminateModelOpen}
        className={
          "w-1/2 absolute bg-complementary_accent_one  rounded-2xl p-10 shadow-xl "
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex capitalize flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">
              Are you sure you want to end this collaboration agreement?
            </div>
            <div className="flex gap-2">
              <button
                className="bg-mid_tone_three btn py-3 px-5 rounded-xl w-72
          "
                onClick={() => {
                  setTerminateModelOpen(false);
                  setTerminatingId(null);
                }}
              >
                No, Keep Agreement
              </button>
              <button
                className="bg-[#E4E1E1] btn py-3 px-5 rounded-xl w-72
          "
                onClick={() => {
                  setTerminateModelOpen(false);
                  terminate();
                }}
              >
                Yes, End Agreement
              </button>
            </div>
          </div>
        }
      />
      <div className="text-subtitle_one px-4 py-4 flex  w-full justify-between max-md:flex-col">
        <div>My Collaborator Pool</div>
        <div className="border rounded gap-1 flex">
          {/* <div className=" p-2 flex gap-1 items-center rounded-lg shadow-sm"> */}
          {/* <input
            type="search"
            onChange={(e) => {
              setQs(e.target.value);
              if (data && data.length) {
                setData([]);
              }
            }}
            className="  p-1 "
            placeholder="Search by location"
          />
          <button
            onClick={handleSearch}
            disabled={!qs}
            className={classNames(
              {
                "submit-btn-bg-color": qs,
              },
              " text-white px-4 py-2 rounded max-md:p-1"
            )}
          >
            <FontAwesomeIcon
              icon={faSearch}
              // className="h-2 w-2 text-gray-400 border  rounded-full "
              aria-hidden="true"
            />
            Search
          </button> */}
          {/* </div> */}
        </div>
      </div>
      <div className="relative overflow-auto  h-72">
        {data && data.length ? (
          <table className="w-full ">
            <thead className="  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
              <tr className="flex justify-between px-10 py-3 max-md:p-2  max-md:w-full max-md:items-start  sticky">
                <th scope="col" className=" ">
                  Name
                </th>
                <th scope="col" className=" ml-4">
                  Message
                </th>
                <th scope="col" className="mr-36 max-md:m-0 ">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="relative border">
              {data.map(({ full_name, collaboration_id }, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={classNames(
                      { "border-b": index !== expandedRow },
                      "bg-white dark:bg-gray-800 dark:border-gray-700 flex justify-between px-10 py-4 border-t max-md:p-2 max-md:w-full"
                    )}
                  >
                    <td className="w-32">{full_name}</td>
                    <td
                      className=" cursor-pointer"
                      onClick={() => setNotesModal(true)}
                    >
                      <img
                        src={"/assets/icons/provider/new-email.png"}
                        className="w-6 h-6 mr-5"
                      />
                    </td>
                    <td className="pr-16  text-complementary_accent_three flex max-md:p-0">
                      <div
                        className="flex gap-1 items-center"
                        onClick={() => {
                          setTerminateModelOpen(true);
                          setTerminatingId(collaboration_id);
                        }}
                      >
                        <img
                          src={"/assets/icons/provider/terminate.png"}
                          className="w-4 h-4 mr-2"
                        />
                        <span className="cursor-pointer ">
                          Terminate Agreement
                        </span>
                      </div>
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr className="border-b">
                      <td colSpan="6">
                        <CollaboratorDetailsView />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex py-10 justify-center w-full mt-10">
            No Collaboration Found
          </div>
        )}
      </div>
    </div>
  );
}

export default CollaboratorListApp;
