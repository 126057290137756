import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToggleButtonGroup } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import Checkboxes from "./week-selections/Checkboxes";
import { Close } from "@mui/icons-material";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useState } from "react";

function CreateEvent({ open, setOpen, handleEventCreation, start, end }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [width] = useWindowDimensions();

  const [schedulingType, setSchedulingType] = React.useState("repeat");
  const [startTime, setStartTime] = React.useState(start);
  const [endTime, setEndTime] = React.useState(end);
  console.log("---startTime", startTime, start, endTime);
  console.log("---end", end, endTime);
  const [saving, setSaving] = useState(false);
  const handleSave = () => {
    setSaving(true);
    const payload =
      schedulingType === "repeat"
        ? {
            startTime,
            endTime,
            schedulingType,
            repeatDays,
          }
        : {
            startTime,
            endTime,
            schedulingType,
          };

    handleEventCreation(payload).finally(() => {
      setSaving(false);
    });

    setOpen(false);
  };
  const [repeatDays, setRepeatDays] = React.useState([]);
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setRepeatDays([...repeatDays, e.target.name]);
    } else {
      setRepeatDays([...repeatDays.filter((x) => x !== e.target.name)]);
    }
  };
  console.log("--endTime", endTime);
  return (
    <div>
      {open ? (
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "900px", // Set your width here
              },
              ".css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  backgroundColor: "#60C5BC",
                },
            },
          }}
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 768 ? { ...style, width: 500 } : style}>
            <div className="flex justify-end cursor-pointer">
              <Close onClick={() => setOpen(false)} />
            </div>
            <ToggleButtonGroup
              value={schedulingType}
              exclusive
              aria-label="text alignment"
              className="cursor-pointer border rounded-3xl "
            >
              <button
                value="repeat"
                onClick={() => setSchedulingType("repeat")}
                aria-label="left aligned"
                className={classNames(
                  {
                    "bg-select_option": schedulingType === "repeat",
                  },
                  " px-4 py-0 border-r font-semibold"
                )}
              >
                <Typography id="Repeat Scheduling">
                  Repeat Scheduling
                </Typography>
              </button>
              <button
                value="once"
                aria-label="left aligned"
                onClick={() => setSchedulingType("once")}
                className={classNames(
                  {
                    "bg-select_option": schedulingType === "once",
                  },
                  "font-semibold  px-4 py-0 border-l"
                )}
              >
                {/* <ToggleButton
              sx={{
                ".css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                  {
                    backgroundColor: "#60C5BC",
                  },
              }}
              value="once"
              aria-label="centered"
            > */}
                <Typography
                  className=" rounded-lg p-5 "
                  id="One Time Scheduling "
                >
                  One Time Scheduling
                </Typography>
              </button>
              {/* </ToggleButton> */}
            </ToggleButtonGroup>
            <div className="flex gap-2 max-md:flex-col">
              <div className="max-md:w-full">
                <label
                  className={classNames(
                    "block text-sm font-medium my-1 text-gray-900 max-md:w-full"
                  )}
                  htmlFor="Start Date"
                >
                  From
                </label>
                <input
                  type="datetime-local"
                  className="block flex-1
                   border-2  rounded-md  
                   bg-transparent  text-gray-900
                    placeholder:text-gray-400 
                    focus:ring-0 sm:text-sm sm:leading-6
                     bg-[#EFF6FF] py-2 pl-2 
                  max-md:w-full
                  "
                  defaultValue={moment(startTime).format("YYYY-MM-DDTHH:MM")}
                  placeholder="Start Date"
                  id="Start Date"
                  step={900}
                  onChange={(e) => setStartTime(new Date(e.target.value))}
                />
              </div>
              <div>
                <label
                  className={classNames(
                    "block text-sm font-medium my-1 text-gray-900"
                  )}
                  htmlFor="to"
                >
                  To
                </label>
                <input
                  type="datetime-local"
                  className="block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] py-2 pl-2 "
                  placeholder="to"
                  step={900}
                  id="to"
                  defaultValue={moment(endTime).format("YYYY-MM-DDTHH:MM")}
                  onChange={(e) => setEndTime(new Date(e.target.value))}
                />
              </div>
            </div>
            {schedulingType === "repeat" ? (
              <div className="my-1 mt-2" style={{ color: "#626262" }}>
                <span>Select the day/days you want to repeat </span>
                <Checkboxes handleCheckboxChange={handleCheckboxChange} />
              </div>
            ) : null}
            <button
              className={classNames(
                "text-button_font p-4 py-3 my-2 submit-btn-bg-color  hover:border-transparent  border rounded-md w-24 text-light_tone_one bg-mid_tone_two cursor-pointer"
              )}
              disabled={saving}
              onClick={handleSave}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}

export default CreateEvent;
