import Registration from "../patient/registration/Registration";
import Activate from "../containers/common/ActivePage";
import { createBrowserRouter } from "react-router-dom";
import PatientHome from "../containers/patient/PatientHome";
import { PatientDashboard } from "../patient/dashboard/index";
import ProviderManagement from "../patient/dashboard/components/ProviderManagement";
import SearchProvider from "../containers/patient/SearchProvider";
import ProviderFullDetails from "../containers/patient/ProviderFullDetails";
export const patientAuthRoutes = createBrowserRouter([
  {
    path: "/home/patient",
    element: (
      <Activate>
        <PatientHome />
      </Activate>
    ),
  },
  {
    path: "/patient/registration",
    element: (
      <Activate>
        <Registration />
      </Activate>
    ),
  },

  {
    path: "/patient/dashboard",
    element: (
      <Activate>
        <PatientDashboard />
      </Activate>
    ),
  },
  {
    path: "patient/provider-management",
    element: (
      <Activate>
        <ProviderManagement />
      </Activate>
    ),
  },
  {
    path: "*",
    element: (
      <Activate>
        <Activate />
      </Activate>
    ),
  },
]);
export const patientUnAuthRoutes = [
  {
    path: "/search-provider",
    element: <SearchProvider />,
  },
  {
    path: "/provider-details",
    element: <ProviderFullDetails />,
  },
];
