import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardHoc from "../../../DashboardHoc";
import BankDetailsForm from "./BankDetailsForm";
import ProfileSecurity from "./ProfileSecurity";
import PersonalDetails from "./PersonalDetails";
import DocumentCenter from "./DocumentCenter";
import PaymentSubscription from "./Payment&Subscription";
import { AuthContext } from "../../../../../App";
import useProviderActions from "../../../../../actions/useProviderActions";
import { ClipLoader } from "react-spinners";

function PersonalProfile() {
  const { userDetails } = useContext(AuthContext);

  const [providerDetails, setProviderDetails] = useState(null);
  const { getProviderDetails } = useProviderActions();
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProviderDetails(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setProviderDetails(res.data);
          }
        }
      });
  }, []);
  const navigate = useNavigate();

  return (
    <DashboardHoc>
      <div className="w-full text-body_font_three flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">Personal Profile</div>
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        {providerDetails === null ? (
          <>
            <ClipLoader
              color={"#E7E6EC"}
              loading={true}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </>
        ) : (
          <>
            <PersonalDetails userDetails={providerDetails} />
            <ProfileSecurity
              additionalContacts={
                providerDetails.additional_contacts
                  ? providerDetails.additional_contacts
                  : []
              }
            />
            <PaymentSubscription userDetails={providerDetails} />
            <BankDetailsForm userDetails={providerDetails} />
          </>
        )}
        <div className="flex justify-center">
          <Link to="/provider/profile-management">
            <button
              className={
                "border  py-3 px-5 text-light_tone_one font-bold rounded-xl w-36 submit-btn-bg-color"
              }
            >
              Submit
            </button>
          </Link>
        </div>
      </div>
    </DashboardHoc>
  );
}

export default PersonalProfile;
