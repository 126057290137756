import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import classNames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import { tabListPatientDashboard } from "./utils";
import { uniqueId } from "lodash";
import { Drawer } from "@mui/material";
import { AuthContext } from "../../../App";
import Footer from "../../../containers/main/components/Footer";
import useUserActions from "../../../actions/useUserActions";
import useWindowDimensions from "../../../containers/utils/useWindowDimensions";
import ProfilePicture from "../../../components/ProfilePicture";
function DashboardHoc(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isAuth, userDetails } = useContext(AuthContext);
  // const [userDetails, setUser] = useState({});
  // const [activeTab, setTabIndex] = useState(0);
  const [width] = useWindowDimensions();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("lastRoute", window.location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      if (userDetails && userDetails.usertype === "provider") {
        if (userDetails.is_new) {
          //todo remove comments
          // navigate("/registration/and/verification");
          // return;
        }
      }
    }
  });
  const location = useLocation();
  const MobileSideBar = () => {
    if (width < 768) {
      return (
        <>
          <div className="" onClick={() => setIsNavOpen((prev) => !prev)}>
            <svg
              className={cn({ hidden: isNavOpen }, "fill-current h-6 w-6 m-4")}
              viewBox="0 0 20 20"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </div>
          <Drawer open={isNavOpen} onClose={() => setIsNavOpen(false)}>
            <ul className="flex flex-col gap-5 pl-7 pr-2 mt-10 ">
              {tabListPatientDashboard.map((tab) => {
                return (
                  <li
                    key={tab.id + uniqueId}
                    // onClick={() => {
                    //   setTabIndex(tab.id);
                    // }}
                    className={classNames(
                      {
                        "bg-light_tone_two": location.pathname.includes(
                          tab.pageName
                        ),
                      },
                      "cursor-pointer p-2 rounded-lg"
                    )}
                  >
                    <Link to={tab.link}>
                      <div className="flex gap-2">
                        <img src={tab.icon}></img>
                        <span className="text-body_font_three">{tab.name}</span>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Drawer>
        </>
      );
    }
  };
  return (
    <div>
      <div className="flex flex-col  bg-zinc-100">
        <DashBoardHeader />
        <MobileSideBar />
        <div className="flex w-full ">
          <div className="bg-light_tone_one w-1/5 max-md:hidden">
            <div className="py-10 ">
              <ul className="flex flex-col gap-5 pl-7 pr-2 ">
                {tabListPatientDashboard.map((tab) => {
                  return (
                    <li
                      // onClick={() => {
                      //   setTabIndex(tab.id);
                      // }}
                      key={tab.id + uniqueId}
                      className={classNames(
                        {
                          "bg-light_tone_two": location.pathname.includes(
                            tab.pageName
                          ),
                        },
                        "cursor-pointer p-2 rounded-lg"
                      )}
                    >
                      <Link to={tab.link}>
                        <div className="flex gap-2">
                          <img src={tab.icon}></img>
                          <span className="text-body_font_three">
                            {tab.name}
                          </span>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {props.children}
        </div>
        {/* <div className="flex mt-3 h-96 flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
        <div className="px-24 mb-3 max-md:px-0">
          <div className="text-3xl max-md:p-2 text-center mt-20">
            
          </div>
        </div>
      </div> */}
      </div>
      <Footer isPatient={true} />
    </div>
  );
}

export const DashBoardHeader = () => {
  const [isOpen, setOpen] = useState(false);

  const ProfileOptionsMemo = useMemo(() => (
    <ProfileOptions isOpen={isOpen} setOpen={setOpen} />
  ));

  return (
    <>
      <div
        className={cn(
          "flex items-center justify-between px-2 py-2 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full bg-white"
        )}
      >
        <Link to="/" className="text-left">
          <img
            alt=""
            loading="lazy"
            src="/assets/images/logo.png"
            className="self-stretch max-w-full mix-blend-multiply ml-2 w-36"
          />
        </Link>
        {ProfileOptionsMemo}
      </div>
    </>
  );
};

const ProfileOptions = ({ setOpen, isOpen }) => {
  const { logout } = useUserActions();
  const { userDetails } = useContext(AuthContext);
  return (
    <div className="relative inline-block text-left">
      <div
        className="self-stretch max-w-full mix-blend-multiply ml-2 w-16"
        id="menu-button"
        onClick={() => setOpen(!isOpen)}
      >
        <ProfilePicture picture={userDetails.picture} />
      </div>

      {isOpen && (
        <div
          // onClick={() => setOpen(false)}
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div className="py-1" role="none">
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              Account settings
            </Link>
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
            >
              Support
            </Link>
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-2"
            >
              License
            </Link>
            <button
              type="submit"
              className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
              role="menuitem"
              onClick={logout}
              tabindex="-1"
              id="menu-item-3"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardHoc;
