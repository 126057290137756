import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import useProviderActions from "../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../App";
import { Upload } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

export default function DocumentCenter() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [width] = useWindowDimensions();
  const { userDetails } = useContext(AuthContext);

  const [data, setData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [uploadedDocumentList, setUploadedDocumentList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [docName, setDocName] = useState("");

  const { getDocumentList, deleteDocument } = useProviderActions();
  const [showUploadFailedModal, setShowUploadFailedModal] = useState(false);
  const getDocuments = () => {
    return getDocumentList().then(({ data }) => {
      setUploadedDocumentList(data.data);
    });
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
    setSelectedDocumentType("");
  };

  useEffect(() => {
    getDocuments();
  }, []);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {})
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        const idx = data.findIndex(
          (cat) => cat.id == userDetails.provider_details.category_id
        );
        if (idx > -1)
          handleCategoryChange({
            value: userDetails.provider_details.category_id,
            label: userDetails.provider_details.category_name,
          });
        else {
          setSelectedCategory(data[0]);
        }
      });
  }, []);

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const [isUploading, setUploading] = useState(false);

  const { uploadDocument } = useProviderActions();
  const handleFileChange = (event) => {
    setUploading(true);

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("document", file);
    formData.append("document_type", selectedDocumentType.value);
    formData.append("document_name", docName);
    formData.append("category", selectedCategory.category_id);
    uploadDocument(formData).then((res) => {
      if (res.error) {
        setShowUploadFailedModal(true);
      }
      getDocuments();
      setUploading(false);
    });
  };

  // if (width < 768) {

  const handleDocumentTypeChange = (e) => {
    setSelectedDocumentType(e);
  };

  const categoryOptions = data.map((category) => ({
    label: category.category_name,
    value: category.category_id,
  }));
  const documentTypeOptions =
    selectedCategory &&
    data
      .find((category) => category.category_id == selectedCategory.category_id)
      ?.document_types.map((documentType) => ({
        label: documentType.document_type,
        value: documentType.document_type_id,
      }));
  if (!data) {
    return null;
  }

  return (
    <div className="my-4  px-10 max-md:p-0">
      <div className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="max-md:px-2 ">
          <span className="block font-semibold text-xl	max-md:p-1">
            Document Centre
            <span className="text-body_font_three mx-4 ">
              (Please upload or delete your documents here)
            </span>
          </span>
          <div className=" bg-complementary_accent_one  rounded-2xl ">
            <div className=" max-md:p-2">
              <div
                className="bg-light_tone_one py-8 px-4 rounded-2xl mt-4
         flex gap-2 items-center justify-between
         max-md:flex-col max-md:item-start max-md:px-0 
         
          "
              >
                <input
                  className="border w-60 p-2 rounded-lg max-md:w-80"
                  placeholder="Document Name"
                  onChange={(e) => setDocName(e.target.value)}
                />

                <Select
                  classNames={{
                    control: (state) =>
                      classNames(
                        {},
                        "w-60  max-md:w-80 h-10 rounded-xl select-box-upload-doc  "
                      ),
                  }}
                  defaultValue={selectedOption}
                  options={documentTypeOptions}
                  placeholder="Document Type"
                  value={selectedDocumentType}
                  onChange={handleDocumentTypeChange}
                />

                <input
                  type="file"
                  ref={fileInputRef}
                  // accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {/* <button
                  className="ml-10 max-lg:w-96 mx-md:ml-0 max-md:w-full"
                  onClick={handleUploadClick}
                >
                  <img src="/assets/images/document-upload-btn.png"></img>
                </button> */}

                <button
                  className="ml-10 w-72 max-md:w-80 max-md:ml-0 border bg-select_option px-4 py-2 rounded-md"
                  onClick={handleUploadClick}
                >
                  {/* <img src="/assets/images/document-upload-btn.png"></img> */}
                  {isUploading ? "Uploading" : "Upload"} Document{" "}
                  {isUploading ? (
                    <ClipLoader
                      color={"#000000"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    <Upload />
                  )}
                </button>
              </div>
              <div className="text-error flex justify-between">
                <span>*Please select your provider catagory carefully</span>
                <span>*Document should be uploaded in PDF format</span>
              </div>
              <div className="relative overflow-x-auto max-md:max-w-80">
                <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Document Type
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Document Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Upload Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Verification Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedDocumentList &&
                    uploadedDocumentList.length === 0 ? (
                      <tr>
                        <td className="text-gray_out_text" colSpan={10}>
                          Please Upload Document*
                        </td>
                      </tr>
                    ) : (
                      uploadedDocumentList.map((doc) => {
                        return (
                          <tr
                            key={doc.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {doc.document_type}
                            </th>
                            <td className="px-6 py-4">{doc.document_name}</td>
                            <td className="px-6 py-4">
                              <span className="flex items-center gap-16">
                                <span className="flex items-center gap-2">
                                  <img
                                    alt=""
                                    loading="lazy"
                                    src="/assets/images/verified-green-tick.png"
                                    className="w-5 h-4"
                                  />{" "}
                                  Uploaded Successful{" "}
                                </span>
                                <img
                                  alt=""
                                  loading="lazy"
                                  src="/assets/images/document-trash-icon.png"
                                  className="w-4 h-5 cursor-pointer"
                                  onClick={() => {
                                    deleteDocument(doc.id).then(() => {
                                      getDocuments();
                                    });
                                  }}
                                />
                              </span>
                            </td>
                            <td className="px-6 py-4 capitalize">
                              {doc.verification_status}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
