import React, { useContext } from "react";
import { AuthContext } from "../App";

const ProfilePicture = ({
  name = "",
  width = "50px",
  height = "50px",
  picture = "",
  classes = "",
}) => {
  const styles = {
    width,
    height,
    borderRadius: "50%",
    backgroundColor: "#9e9e9e",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: "bold",
  };

  const stylesPic = {
    width,
    height,
    borderRadius: "100",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
  };

  if (picture && picture !== "none") {
    return (
      <div style={stylesPic}>
        <img className={classes} src={picture} />
      </div>
    );
  }
  const { userDetails = {} } = useContext(AuthContext);
  const firstLetter = name
    ? name.charAt(0).toUpperCase()
    : userDetails?.first_name
    ? userDetails?.first_name.charAt(0).toUpperCase()
    : "";

  return <div style={styles}>{firstLetter}</div>;
};

export default ProfilePicture;
