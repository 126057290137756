import React, { useState } from "react";
import ProfilePicture from "../../components/ProfilePicture";
import { Link } from "react-router-dom";
import { PersistQueryLink } from "../../PersistQueryLink";

function ProviderDetails({ data }) {
  return (
    <div className=" bg-gray_out h-[500] rounded-lg shadow overflow-y-auto mx-4 py-4 max-md:w-80 px-8 max-md:px-2">
      <div className="flex items-center gap-3 p-2 max-md:flex-wrap">
        {data.picture ? (
          <img src={data.picture} width="64px" height="64px" />
        ) : (
          <ProfilePicture name={data.first_name} width="64px" height="64px" />
        )}
        <div className="py-2 w-4/5">
          <span className="text-body_font_two font-medium ">
            {data.full_name}
          </span>

          <span className="block text-gray_out_text">{data.category_name}</span>
        </div>
        <PersistQueryLink to={"/provider-details#book-appointment"}>
          <button className="submit-btn-bg-color text-white p-4 rounded-lg mx-4 w-48 shadow-md">
            Book Appointment
          </button>
        </PersistQueryLink>
      </div>
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <div className="px-4">
        <div className="py-4">
          <div className="flex items-center gap-2 font-bold">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/verified-green-tick.png"
              className="w-5 h-4"
            />
            Medical Registration Verified
          </div>
          <div className="flex items-center gap-2 font-bold">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/verified-green-tick.png"
              className="w-5 h-4"
            />
            95% (227 patients)
          </div>
        </div>
        <div className="flex flex-col gap-3 text-gray_out_text ">
          <p>
            Dr. Sumanth M. Shetty completed his post graduation in masters of
            dental surgery[ mds] in the field of Pedodontics and preventive
            dentistry, from the esteemed Bapuji Dental College, Davangere, a
            Karnataka, India. He is now serving as professor and HOD in the
            department of pediatric dentistry.
          </p>
          <p>
            He is also a practicing implantology having completed his implant
            course from the prestigious Nobel Biocare, Sweden. his keen interest
            in the field of implant dentistry has led him to accrue further
            certifications in this field. He is also certified in complicated
            sinus lift implant procedures and advanced full mouth rehabilitation
            from Ossteum, Lisbon and Jakarta respectively.
          </p>
          <p>
            At chisel dental clinic we are proud of our unique brand of gentle
            family dentistry. By explaining your treatment choices every step of
            the way we help you make an informed decision. We treat you with
            honesty, compassion, and respect.
          </p>
        </div>
      </div>
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <PersistQueryLink to={"/provider-details"}>
        <button
          // onClick={() => handleSendRequest(data.user_id)}
          className="submit-btn-bg-color text-white p-4 rounded-lg mx-4 w-48 shadow-md"
        >
          View More Details
        </button>
      </PersistQueryLink>
    </div>
  );
}

export default ProviderDetails;
