import React, { useEffect, useState } from "react";

function WhyUs() {
  const images = [
    [
      "/assets/images/freedom-of-work-01.png",
      `/assets/images/freedom-of-work-02.png`,
    ],
    ["/assets/images/no-target-01.png", `/assets/images/no-target-02.png`],
    [
      "/assets/images/your-private-practice-01.png",
      `/assets/images/your-private-practice-02.png`,
    ],
    [
      "/assets/images/you-decide-the-rate-01.png",
      "/assets/images/you-decide-the-rate-02.png",
    ],
  ];
  const [hovered, setHovered] = useState(null);
  const handleHoverIndex = (index) => {
    setHovered(index);
  };
  const handleHoveLeave = () => {
    setHovered(null);
  };

  return (
    <React.Fragment>
      <div className="mt-36 mb-12    ml-5 text-4xl font-bold tracking-wider text-center capitalize text-slate-900 max-md:mt-10 max-md:max-w-full">
        Why us?
      </div>

      <div className="max-md:hidden mt-10 overflow-y-auto relative">
        {images.map((img, idx) => {
          let index = idx + 1;
          return (
            <div
              key={idx}
              className="flex px-8 transition-all  ease-in-out delay-150  duration-300 items-center justify-around mt-3 relative"
            >
              {index % 2 !== 0 ? (
                <>
                  <img
                    className="mr-96  "
                    src={`${img[0]}`}
                    style={{
                      opacity: hovered !== index ? 1 : 0,
                      display: hovered !== index ? "block" : "none",

                      transition: "opacity 0.5s ease-in-out",
                    }}
                    onMouseEnter={() => handleHoverIndex(index)}
                    onMouseLeave={handleHoveLeave}
                  />
                  <img
                    className="mr-96  "
                    src={`${img[1]}`}
                    style={{
                      opacity: hovered === index ? 1 : 0,
                      display: hovered === index ? "block" : "none",
                      transition: "opacity 0.5s ease-in-out",
                    }}
                    onMouseEnter={() => handleHoverIndex(index)}
                    onMouseLeave={handleHoveLeave}
                  />
                  <img
                    width={105}
                    height={90}
                    className="z-10 absolute"
                    src="/assets/images/line-img-1.png"
                  />

                  <span className="text-6xl mr-64">
                    {index.toString().padStart(2, "0")}
                  </span>
                </>
              ) : (
                <>
                  <span className="text-6xl ml-64">
                    {index.toString().padStart(2, "0")}
                  </span>
                  <img
                    width={105}
                    height={90}
                    className="z-10 absolute"
                    src="/assets/images/line-img-1.png"
                  />

                  <div id="why-us-line" className="line"></div>
                  <img
                    className="ml-96  "
                    src={`${img[1]}`}
                    style={{
                      opacity: hovered === index ? 1 : 0,
                      display: hovered === index ? "block" : "none",
                      transition: "opacity 0.5s ease-in-out",
                    }}
                    onMouseEnter={() => handleHoverIndex(index)}
                    onMouseLeave={handleHoveLeave}
                  />
                  <img
                    src={`${img[0]}`}
                    style={{
                      opacity: hovered !== index ? 1 : 0,
                      display: hovered !== index ? "block" : "none",

                      transition: "opacity 0.5s ease-in-out",
                    }}
                    onMouseEnter={() => handleHoverIndex(index)}
                    onMouseLeave={handleHoveLeave}
                    className="ml-96"
                  />
                </>
                // </p>
              )}{" "}
            </div>
          );
        })}
      </div>
      <div className=" lg:hidden mt-10 overflow-y-auto relative">
        {images.map((img, idx) => {
          let index = idx + 1;
          return (
            <div
              key={idx}
              className="flex flex-col   px-8 transition-all  ease-in-out delay-150  duration-300 items-center mt-3 "
            >
              <>
                <img
                  src={`${img[0]}`}
                  style={{
                    opacity: hovered !== index ? 1 : 0,
                    display: hovered !== index ? "block" : "none",

                    transition: "opacity 0.5s ease-in-out",
                  }}
                  onMouseEnter={() => handleHoverIndex(index)}
                  onMouseLeave={handleHoveLeave}
                />
                <img
                  src={`${img[1]}`}
                  style={{
                    opacity: hovered === index ? 1 : 0,
                    display: hovered === index ? "block" : "none",
                    transition: "opacity 0.5s ease-in-out",
                  }}
                  onMouseEnter={() => handleHoverIndex(index)}
                  onMouseLeave={handleHoveLeave}
                />
                <br />

                <span className="text-6xl ">
                  {index.toString().padStart(2, "0")}
                </span>
              </>
            </div>
          );
        })}
      </div>

      <br />
    </React.Fragment>
  );
}

export default WhyUs;
