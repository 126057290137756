import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ProfilePicture from "../../../../components/ProfilePicture";
import useUserActions from "../../../../actions/useUserActions";
import useCollabActions from "../../../../actions/useCollabActions";

function SearchNewCollaboratorsDetails({ data }) {
  const { sendCollabRequest } = useCollabActions();

  const handleSendRequest = (id) => {
    sendCollabRequest(data.user_id);
  };
  const requestCritarea = [
    "Child and Adolescent Psychiatry Fellowship ",
    "Addiction Psychiatry Fellowship ",
    "Women’s Mental Health Fellowship ",
    "Geriatric Psychiatry Fellowship ",
    "Sleep Medicine Fellowship ",
    "Consultation–Liaison Psychiatry Fellowship",
    "Forensic Psychiatry Fellowship ",
  ];
  return (
    <div className=" bg-gray_out h-[525px] rounded shadow overflow-y-auto mx-4 py-4 max-md:w-80">
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <div className="flex flex-col gap-2 px-4">
        <div
          className="text-body_font_two font-semibold"
          style={{
            color: "#494A52",
          }}
        >
          Request Criteria
        </div>
        <div className="flex gap-4 flex-wrap">
          {requestCritarea.map((item) => (
            <span key={item} className="bg-white px-6 rounded-xl shadow py-2">
              {item}
            </span>
          ))}
        </div>
      </div>{" "}
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <div className="flex flex-col gap-2 px-4">
        <div
          className="text-body_font_two font-semibold"
          style={{
            color: "#494A52",
          }}
        >
          Collaboration Rate
        </div>
        <div className="font-semibold" style={{ fontSize: 28 }}>
          ${data.collaboration_rate}
        </div>
      </div>
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <div className="flex items-center gap-3 p-2">
        {data.picture ? (
          <img
            src={data.picture}
            width="64px"
            height="64px"
            className="rounded-full"
          />
        ) : (
          <ProfilePicture name={data.first_name} width="64px" height="64px" />
        )}
        <div className="py-2 w-1/2">
          <span className="text-body_font_two font-medium ">
            {data.full_name}
          </span>

          <span className="block text-gray_out_text">{data.category_name}</span>
        </div>
      </div>
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <div className="px-4">
        <div className="py-4">
          <div className="flex items-center gap-2 font-bold">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/verified-green-tick.png"
              className="w-5 h-4"
            />
            Medical Registration Verified
          </div>
          <div className="flex items-center gap-2 font-bold">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/verified-green-tick.png"
              className="w-5 h-4"
            />
            95% (227 patients)
          </div>
        </div>
        <div className="flex flex-col gap-3 text-gray_out_text ">
          <p>
            Dr. Sumanth M. Shetty completed his post graduation in masters of
            dental surgery[ mds] in the field of Pedodontics and preventive
            dentistry, from the esteemed Bapuji Dental College, Davangere, a
            Karnataka, India. He is now serving as professor and HOD in the
            department of pediatric dentistry.
          </p>
          <p>
            He is also a practicing implantology having completed his implant
            course from the prestigious Nobel Biocare, Sweden. his keen interest
            in the field of implant dentistry has led him to accrue further
            certifications in this field. He is also certified in complicated
            sinus lift implant procedures and advanced full mouth rehabilitation
            from Ossteum, Lisbon and Jakarta respectively.
          </p>
          <p>
            At chisel dental clinic we are proud of our unique brand of gentle
            family dentistry. By explaining your treatment choices every step of
            the way we help you make an informed decision. We treat you with
            honesty, compassion, and respect.
          </p>
        </div>
      </div>
      <div className="flex-grow border-t border-gray-400 my-4"></div>
      <button
        onClick={() => handleSendRequest(data.user_id)}
        className="submit-btn-bg-color text-white p-4 rounded mx-4 w-48 shadow-md"
      >
        Start Agreement
      </button>
    </div>
  );
}

export default SearchNewCollaboratorsDetails;
