import React, { useState } from "react";
import ModalComponent from "./ModalComponent";
import classNames from "classnames";

const Card = ({
  name,
  rate,
  helpingRate = " Pay Period*",
  isActive = false,
  setSelectedCard,
}) => {
  return (
    <div
      className={classNames(
        { "bg-gray_out text-gray_out_text": !isActive },
        { ["border-2 border-dark_tone"]: isActive },
        "w-80 rounded-2xl  p-10 justify-center flex flex-col items-center cursor-pointer max-md:w-40 max-md:text-xl"
      )}
      onClick={() => setSelectedCard(name)}
    >
      <div className="text-2xl   font-extrabold mb-2">{name}</div>
      <button
        // disabled={!isActive}
        className={classNames(
          {
            " membership-card-payment-btn": !isActive,
          },
          "w-52 max-md:w-36  h-14    text-complementary_accent_one bg-dark_tone rounded-xl shadow-md flex justify-center items-center align-middle p-3 max-md:py-4"
        )}
      >
        <div className="font-bold text-xxl max-md:text-xl">${rate}/</div>
        <small
        // className="max-w-20 text-subtitle_three max-md:text-sm"
        >
          {helpingRate}
        </small>
      </button>
    </div>
  );
};
function PaymentOptionsModal({
  isOpen,
  onClose,
  setSelectedCard,
  selectedCard,
}) {
  const body = (
    <div className="p-2 max-md:py-3 ">
      <div className="text-xxl font-semibold">MemberShip</div>
      <div className="flex justify-between my-3 flex-wrap gap-3 w-[660px] max-md:w-full max-md:h-full max-md:overflow-y-auto ">
        <Card
          setSelectedCard={setSelectedCard}
          name={"Essential"}
          rate={"16.99"}
          isActive={selectedCard === "Essential"}
        />
        <Card
          setSelectedCard={setSelectedCard}
          name={"Balance"}
          rate={"19.99"}
          isActive={selectedCard === "Balance"}
        />
        <Card
          setSelectedCard={setSelectedCard}
          name={"Freedom"}
          rate={"24.99"}
          isActive={selectedCard === "Freedom"}
        />
        <Card
          setSelectedCard={setSelectedCard}
          name={"Trial"}
          rate={"0"}
          isActive={selectedCard === "Trial"}
          helpingRate={"for 1st Appointment"}
        />
      </div>
      <div className="text-xxl font-semibold">Billing Details</div>

      <div className="w-full flex justify-center my-20">Pending</div>

      <div className="flex gap-3 flex-wrap">
        <button
          className="bg-complementary_accent_three btn  py-3 px-5 rounded-xl w-48 max-md:w-full"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          disabled={true}
          className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-48 max-md:w-full max-md:border"
        >
          Subscribe
        </button>
      </div>
    </div>
  );
  return (
    <ModalComponent isOpen={isOpen} body={body} className={""}></ModalComponent>
  );
}

export default PaymentOptionsModal;
