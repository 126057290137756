import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../../../App";
// import "./SchedulingAndCommunication.css";
import DatePicker from "react-datepicker";
import DashboardHoc from "../../DashboardHoc";
import CalendarAppointments from "../widgets/CalendarAppointments";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import MessageWidget from "../widgets/MessageWidget";

function SchedulingAndCommunication() {
  const { userDetails } = useContext(AuthContext);
  const today = new Date();
  const [width] = useWindowDimensions();

  return (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5  max-md:w-full max-md:p-2">
        {width > 768 ? (
          <div className="text-3xl  capitalize">Scheduling & Communication</div>
        ) : (
          <div className="text-2xl  capitalize">Scheduling & Communication</div>
        )}
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        {width > 768 ? (
          <div
            id="schedulePicker"
            className="flex w-full  gap-3 max-md:flex-col"
            style={{
              maxHeight: "500px",
              minHeight: "500px",
            }}
          >
            <CalendarAppointments />
            <MessageWidget />
          </div>
        ) : (
          <div
            id="schedulePicker"
            className="flex w-full  gap-3 max-md:flex-col max-md:px-2"
          >
            <CalendarAppointments />
            <MessageWidget />
          </div>
        )}
      </div>
      <br />
    </DashboardHoc>
  );
}

export default SchedulingAndCommunication;
