import React from "react";
import Footer from "../main/components/Footer";
import Header from "../common/Header";
import PlanAndPaymentsUnAuth from "../../patient/registration/PlanAndPaymentsUnAuth";
import SearchProviderForm from "./SearchProviderForm";

function SearchProvider() {
  return (
    <div>
      <div className="flex flex-col items-center  bg-zinc-100">
        <div className="flex flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
          <Header isPatent={true} />
          <div className="p-10 max-md:p-2">
            <PlanAndPaymentsUnAuth />
            <SearchProviderForm />
          </div>
        </div>
      </div>
      <Footer isPatent={true} />
    </div>
  );
}

export default SearchProvider;
