import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import ModalComponent from "./modals/ModalComponent";
import { Divider } from "@mui/material";

const ProfilePictureUploader = ({ picture, onImageChange, isDisabled }) => {
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [preview, setPreview] = useState(picture);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    setModalOpen(true);
  };

  const handleSave = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL(); // Get the cropped image as a data URL
      setPreview(dataURL);
      onImageChange(dataURL); // Set the cropped image to preview
    }
    setModalOpen(false);
  };

  let editor;

  return (
    <div style={{ textAlign: "center" }}>
      <input
        key={isModalOpen}
        disabled={isDisabled}
        type="file"
        onChange={handleImageChange}
      />
      {image && isModalOpen && (
        <ModalComponent
          isOpen={isModalOpen}
          className={
            "w-1/4 absolute bg-complementary_accent_one  rounded-lg p-10 shadow-xl"
          }
          contentLabel="Verification Unsuccessful"
          body={
            <>
              <h2 className="text-subtitle_one"> Update Profile Picture</h2>
              <div className="flex justify-center flex-col items-center">
                <div className="w-full">
                  <Divider />
                </div>
                <AvatarEditor
                  ref={(ref) => (editor = ref)}
                  image={image}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={100} // Make it circular
                  scale={scale}
                  color={[255, 255, 255, 0.6]} // Optional background color for editor
                />

                <div className="flex gap-2">
                  <button
                    className=" text-light_tone_one  border submit-btn-bg-color btn py-3 px-5 rounded-xl w-36
          "
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Update
                  </button>
                  <button
                    className=" border btn  py-3 px-5 rounded-xl w-36
          "
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          }
        />
      )}

      {preview && (
        <div>
          <div
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              borderRadius: "50%", // Circular crop
              overflow: "hidden",
            }}
          >
            <img
              src={preview}
              alt="Cropped Profile"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { ProfilePictureUploader };
