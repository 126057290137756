import React, { useState } from "react";
import { widgets } from "./utils";
import { Checkbox, Divider, FormControlLabel, FormGroup } from "@mui/material";
import _ from "lodash";

const UpdateWidgetsForm = ({
  close,
  onUpdate,
  defaultChecked = [1, 2],
  enableVal,
}) => {
  const [data, setData] = useState(defaultChecked);
  const handleChange = (event) => {
    if (event.target.checked) {
      setData([...data, parseInt(event.target.name)]);
    } else {
      const temp = data.filter((x) => x != event.target.name);
      setData(temp);
    }
  };
  return (
    <div className="w-full">
      <div className=" text-2xl mb-3">Update Home Page</div>
      <Divider />
      <div className="my-4">
        <FormGroup>
          {widgets.map((widget) => {
            if (defaultChecked.includes(widget.id)) return;
            const isChecked = data.includes(widget.id);
            const isDisabled =
              data.length > enableVal && !data.includes(widget.id);
            return (
              <FormControlLabel
                key={widget.name}
                control={
                  <Checkbox
                    checked={isChecked}
                    name={widget.id}
                    onChange={(e) => handleChange(e)}
                  />
                }
                label={widget.name}
                disabled={isDisabled}
              />
            );
          })}
        </FormGroup>
      </div>
      <Divider />
      <div className="flex justify-end gap-3">
        <button
          className="submit-btn-bg-color btn
        text-light_tone_one py-3 
        rounded-xl w-36 max-md:w-full
        my-2
        "
          onClick={() => onUpdate(data)}
        >
          Update
        </button>
        <button
          className="border btn
         py-3 
        rounded-xl w-36 max-md:w-full
        my-2
        "
          onClick={close}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpdateWidgetsForm;
