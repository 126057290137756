import React from "react";
import useGetLocalUserDetails from "../../../../hooks/useGetLocalUserDetails";
import DashboardHoc from "../../DashboardHoc";
import MyStatesApp from "./MyStatesApp";
import QuickSendList from "./QuickSendList";
import CollaboratorListApp from "./CollaboratorListApp";
import SearchNewCollaborators from "./SearchNewCollaborators";
import MyStates from "./MyStates";
import CollaboratorList from "./CollaboratorListList";
import NewCollaboratorList from "./NewCollaboratorList";
import { Link } from "react-router-dom";
import PendingNotes from "./PendingNotes";

export default function CollaborationMain() {
  const { isMd } = useGetLocalUserDetails();
  return !isMd ? (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col  h-4/5 max-md:w-full max-md:p-2 ">
        <div className="text-3xl capitalize">Collaboration</div>
        <div className="text-body_font_three ">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        <section className="w-full  px-10 mb-4  flex flex-col gap-4 max-md:p-2 max-md:w-full">
          <MyStatesApp />
          <QuickSendList />
          <CollaboratorListApp />
          <SearchNewCollaborators />
        </section>
      </div>
    </DashboardHoc>
  ) : (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">Collaboration</div>
        <div className="text-body_font_three ">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        <section className="w-full   flex flex-col gap-4 max-md:p-2">
          <MyStates />
          <PendingNotes />
          <NewCollaboratorList />
        </section>
      </div>
    </DashboardHoc>
  );
}
