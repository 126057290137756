import React from "react";
import DashboardHoc from "../../DashboardHoc";
import { Link } from "react-router-dom";
import ReviewManagementWidget from "../widgets/ReviewManagementWidget";
import MyStatesWidget from "../widgets/MyStatesWidget";
import useWindowDimensions from "../../../utils/useWindowDimensions";

function PerformanceAndInsights() {
  const [width] = useWindowDimensions();

  return (
    <div>
      <DashboardHoc>
        <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5 max-md:w-full max-md:p-2 ">
          <div className="text-3xl capitalize">Performance And Insights</div>
          <div className="text-body_font_three max-md:flow-root  max-md:px-5">
            Manage your information, compliance, appointments, requests,
            security, set your availability and more to make your work smoother.{" "}
            <Link to="#" className="text-blue-600">
              Find out more
            </Link>
          </div>
          {width > 768 ? (
            <div
              className="flex w-full  gap-3 max-md:flex-col "
              style={{ maxHeight: "500px", minHeight: "500px" }}
            >
              <MyStatesWidget />
              <ReviewManagementWidget />
            </div>
          ) : (
            <div className="flex w-full  gap-3 flex-col px-2">
              <MyStatesWidget />
              <ReviewManagementWidget />
            </div>
          )}
        </div>
      </DashboardHoc>
    </div>
  );
}

export default PerformanceAndInsights;
