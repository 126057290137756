import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { Divider, Skeleton } from "@mui/material";
import moment from "moment";
import useProviderActions from "../../../../actions/useProviderActions";
import { AuthContext } from "../../../../App";
import ProfilePicture from "../../../../components/ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function MyProviderList() {
  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const { getMyPatients } = useProviderActions();
  const [patients, setList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyPatients()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96">
      <div className="text-subtitle_one">My Provider List</div>
      <div className="my-4 w-full">
        <div className="flex gap-2  items-center ">
          <div className="flex flex-col gap-2 h-48 overflow-auto w-full">
            <div className="text-gray_out_text  font-normal flex justify-between ">
              <div>Name</div>
              <div>
                Available Date{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="h-4 w-4 text-gray-400 "
                  aria-hidden="true"
                />
              </div>
            </div>
            {isLoading ? (
              <>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>

                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
              </>
            ) : null}
            {patients.map((patient, idx) => {
              return (
                <div key={patient.id}>
                  <div className="font-normal my-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <ProfilePicture picture={patient.picture} />
                        {patient.name}
                      </div>
                      <div className="flex flex-col justify-start items-start ">
                        <div>{patient.next_appointment_date}</div>
                        <div className="text-gray_out_text   ">
                          {patient.next_appointment_time}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-2">
          <Divider />
        </div>
        <br />
        <div className="flex justify-end mb-1">
          <Link
            to="/provider/care-collaboration/patient-list"
            className="flex justify-end mb-2"
          >
            <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
              View Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyProviderList;
