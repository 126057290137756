import React, { useContext, useEffect, useMemo, useState } from "react";
import Footer from "../main/components/Footer";
import { AuthContext } from "../../App";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ModalComponent from "../../components/modals/ModalComponent";
import cn from "classnames";
import classNames from "classnames";
import "react-circular-progressbar/dist/styles.css";
import useUserActions from "../../actions/useUserActions";
import useWindowDimensions from "../utils/useWindowDimensions";
import Home from "./dashboard-main-pages/Home";
import ProfileManagement from "./dashboard-main-pages/profile/personal-profile/ProfileManagement";
import SchedulingAndCommunication from "./dashboard-main-pages/scheduling-and-communication/SchedulingAndCommunication";
import CareAndCollaboration from "./dashboard-main-pages/collaborator/CareAndCollaboration";
import { tabListProviderDashboard } from "./utils";
import { uniqueId } from "lodash";
import { Collapse, Drawer, Tooltip } from "@mui/material";
import ProfilePicture from "../../components/ProfilePicture";
import useProviderActions from "../../actions/useProviderActions";
import { CloseFullscreen } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
function DashboardHoc(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { isAuth, userDetails } = useContext(AuthContext);
  // const [userDetails, setUser] = useState({});
  // const [activeTab, setTabIndex] = useState(0);
  const [width] = useWindowDimensions();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("lastRoute", window.location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      if (userDetails && userDetails.usertype === "provider") {
        if (userDetails.is_new) {
          //todo remove comments
          // navigate("/registration/and/verification");
          // return;
        }
      }
    }
  });
  const location = useLocation();
  const MobileSideBar = () => {
    if (width < 768) {
      return (
        <>
          <div className="" onClick={() => setIsNavOpen((prev) => !prev)}>
            <svg
              className={cn({ hidden: isNavOpen }, "fill-current h-6 w-6 m-4")}
              viewBox="0 0 20 20"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </div>
          <Drawer open={isNavOpen} onClose={() => setIsNavOpen(false)}>
            <ul className="flex flex-col gap-5 pl-7 pr-2 mt-10 ">
              {tabListProviderDashboard.map((tab) => {
                return (
                  <li
                    key={tab.id + uniqueId}
                    // onClick={() => {
                    //   setTabIndex(tab.id);
                    // }}
                    className={classNames(
                      {
                        "bg-light_tone_two": location.pathname.includes(
                          tab.pageName
                        ),
                      },
                      "cursor-pointer p-2 rounded-lg"
                    )}
                  >
                    <Link to={tab.link}>
                      <div className="flex gap-2">
                        <img src={tab.icon}></img>
                        <span className="text-body_font_three">{tab.name}</span>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Drawer>
          {/* <div
            className={classNames(
              {
                hidden: !isNavOpen,
              },
              `
           header 
           ease-in duration-300

            w-full
      justify-between border-b border-gray-400 py-8
      `
            )}
          >
            <nav>
              <section className="">
                <div
                  className={
                    isNavOpen
                      ? "showMenuNav ease-in duration-300"
                      : "hideMenuNav ease-in duration-300"
                  }
                >
                  <div className="flex flex-col ease-in duration-300">
                    <div className="flex justify-evenly">
                      <div
                        className="absolute top-14 right-0 px-4 py-8"
                        onClick={() => setIsNavOpen(false)}
                      >
                        <svg
                          className="h-8 w-8 text-gray-600"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </div>
                    </div>
                    <ul className="flex flex-col gap-5 pl-7 pr-2 mt-10 ">
                      {tabListProviderDashboard.map((tab) => {
                        return (
                          <li
                          key={tab.id + uniqueId}
                            // onClick={() => {
                            //   setTabIndex(tab.id);
                            // }}
                            className={classNames(
                              {
                                "bg-light_tone_two": location.pathname.includes(
                                  tab.pageName
                                ),
                              },
                              "cursor-pointer p-2 rounded-lg"
                            )}
                          >
                            <Link to={tab.link}>
                              <div className="flex gap-2">
                                <img src={tab.icon}></img>
                                <span className="text-body_font_three">
                                  {tab.name}
                                </span>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </section>
            </nav>
            <style>{`
    .hideMenuNav {
      display: none;
    }
    .showMenuNav {
      display: block;
      width: 100%;
      height: 200vh;
      top: 0;
      left: 0;
      background: white;
      z-index: 10;
    
    }
  `}</style>
          </div> */}
        </>
      );
    }
  };
  const [sideBarOpen, setSidebarState] = useState(
    localStorage.getItem("sideBarOpen") === "false" ? false : true
  );
  // console.log(
  //   "---sideBarOpen",
  //   sideBarOpen,
  //   localStorage.getItem("sideBarOpen"),
  //   Boolean()
  // );
  return (
    <div>
      <div className="flex flex-col  bg-zinc-100">
        <DashBoardHeader />
        <MobileSideBar />

        <div className="flex w-full ">
          <div
            className={classNames(
              {
                "w-1/5": sideBarOpen,
                "w-1/10": !sideBarOpen,
              },
              "bg-light_tone_one   max-md:hidden provider-slider"
            )}
          >
            <div className="py-10 ">
              <ul className="flex flex-col gap-5 pl-7 pr-2 ">
                {tabListProviderDashboard.map((tab) => {
                  return (
                    <li
                      key={tab.id + uniqueId}
                      className={classNames(
                        {
                          "bg-light_tone_two": location.pathname.includes(
                            tab.pageName
                          ),
                          "p-4": !sideBarOpen,
                          "p-2": sideBarOpen,
                        },
                        "cursor-pointer  rounded-lg"
                      )}
                    >
                      <Link to={tab.link}>
                        <div className="flex gap-2">
                          {sideBarOpen ? (
                            <img height={20} width={20} src={tab.icon}></img>
                          ) : (
                            <Tooltip title={tab.name}>
                              <img height={25} width={25} src={tab.icon}></img>
                            </Tooltip>
                          )}
                          {sideBarOpen ? (
                            <span className="text-body_font_three">
                              {tab.name}
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {width > 768 ? (
            <div className="flex items-center  max-h-96">
              <button
                onClick={() => {
                  localStorage.setItem("sideBarOpen", !sideBarOpen);
                  setSidebarState(!sideBarOpen);
                }}
                className="bg-light_tone_one py-3 px-4 my-collapse-btn"
              >
                <div className="my-collapse-btn-content">
                  <Tooltip title={"Collapse"}>
                    {/*  */}

                    {!sideBarOpen ? (
                      <FontAwesomeIcon icon={faCaretRight} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretLeft} />
                    )}
                  </Tooltip>
                </div>
              </button>
            </div>
          ) : null}
          {props.children}
        </div>
        {/* <div className="flex mt-3 h-96 flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
        <div className="px-24 mb-3 max-md:px-0">
          <div className="text-3xl max-md:p-2 text-center mt-20">
            
          </div>
        </div>
      </div> */}
      </div>
      <Footer isPatent={false} />
    </div>
  );
}

export const DashBoardHeader = () => {
  const [isOpen, setOpen] = useState(false);

  const ProfileOptionsMemo = useMemo(() => (
    <ProfileOptions isOpen={isOpen} setOpen={setOpen} />
  ));

  return (
    <>
      <div
        className={cn(
          "flex items-center justify-between px-2 py-2 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full bg-white"
        )}
      >
        <Link to="/" className="text-left">
          <img
            alt=""
            loading="lazy"
            src="/assets/images/logo.png"
            className="self-stretch max-w-full mix-blend-multiply ml-2 w-36"
          />
        </Link>
        {ProfileOptionsMemo}
      </div>
    </>
  );
};

const ProfileOptions = ({ setOpen, isOpen }) => {
  const { logout } = useUserActions();
  const { userDetails } = useContext(AuthContext);
  return (
    <div className="relative inline-block text-left">
      <div
        className="self-stretch max-w-full mix-blend-multiply ml-2 w-16"
        id="menu-button"
        onClick={() => setOpen(!isOpen)}
      >
        <ProfilePicture picture={userDetails.picture} />
      </div>

      {isOpen && (
        <div
          // onClick={() => setOpen(false)}
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div className="py-1" role="none">
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-0"
            >
              Account settings
            </Link>
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-1"
            >
              Support
            </Link>
            <Link
              to="#"
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              id="menu-item-2"
            >
              License
            </Link>
            <button
              type="submit"
              className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
              role="menuitem"
              onClick={logout}
              tabindex="-1"
              id="menu-item-3"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const CongratsModal = ({ isOpen, close }) => {
  const { removeWelcomeMessage } = useProviderActions();
  const handleClose = () => {
    removeWelcomeMessage();
    close();
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      className={
        "w-2/3 absolute bg-complementary_accent_one border-none  rounded-lg p-10 shadow-xl"
      }
      contentLabel="Verification Unsuccessful"
      body={
        <div className="flex flex-col gap-3  items-center justify-center">
          <img
            src="/assets/images/congrats.png"
            alt="alert"
            width={76}
            height={71}
          />
          <div className="text-2xl">Congratulations!</div>
          <div className="text-2xl max-md:text-subtitle_two">
            Your Registration with Cure Culture is Complete.
          </div>

          <div className="text-button_font w-2/3 text-center max-md:w-full max-md:text-subtitle_three">
            As a token of appreciation, Cure Culture will cover the cost of your
            Medical Malpractice Insurance registration and first premium as part
            of our onboarding assistance program.
          </div>
          <button
            className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-72 max-md:w-full
  "
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      }
    />
  );
};
export default DashboardHoc;
