import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ProviderDetails from "./ProviderDetails";
import classNames from "classnames";
import ReactSelect from "react-select";
import ProfilePicture from "../../components/ProfilePicture";
import { useLocation, useParams } from "react-router-dom";
import useCollabActions from "../../actions/useCollabActions";
// import useCollabActions from "../../actions/useCollabActions";

function SearchProviderForm() {
  const [expandedRow, setExpandedRow] = useState(-1);
  const [data, setData] = React.useState([
    {
      full_name: "full_name",
      city: "City",
      collaboration_rate: 122,
      first_name: "first_name",
    },
  ]);
  const qs = "";
  //   const [qs, setQs] = React.useState("some");
  //   const { searchProviderForCollab } = useCollabActions();
  //   // useEffect(() => {
  //   const handleSearch = () => {
  //     searchProviderForCollab(qs).then((res) => {
  //       if (res.status === 200) {
  //         if (res.data) {
  //           setData(res.data);
  //         }
  //       }
  //     });
  //   };
  // },
  const handleChange = (e) => {
    // console.log()
  };
  const options = [
    { label: "One", value: "one" },
    { label: "two", value: "two" },
    { label: "three", value: "three" },
  ];
  console.log("data--", data);
  if (!data) return;
  return (
    <section
      className="my-4 text-dark_tone capitalize "
      aria-label="Search Provider"
      id="Search Provider"
    >
      <div className="text-2xl  my-8 text-color-1 font-semibold ">
        Search Provider
      </div>
      <div className="bg-white w-full p-10 ease-in duration-300  rounded-lg shadow-lg  max-md:p-2">
        <div className="text-subtitle_one my-2">Search Filter</div>
        <div className="bg-gray_out rounded-xl gap-1  flex flex-wrap justify-between w-full items-center px-10 py-4 max-md:flex-col max-md:px-2">
          <ReactSelect
            classNames={{
              control: () => classNames("w-full h-10 select-box "),
            }}
            onChange={handleChange}
            options={options}
            placeholder="Provider Type"
            className="w-1/4 max-md:w-full"
          />
          <ReactSelect
            classNames={{
              control: () => classNames("w-full h-10 select-box"),
            }}
            onChange={handleChange}
            options={options}
            placeholder="Condition"
            className="w-1/4 max-md:w-full"
          />
          <ReactSelect
            classNames={{
              control: () => classNames("w-full h-10 select-box"),
            }}
            onChange={handleChange}
            options={options}
            placeholder="Services"
            className="w-1/4 max-md:w-full"
          />

          <button className="submit-btn-bg-color  text-white rounded py-4 px-5 max-md:w-full">
            Submit
          </button>
        </div>
        <div className="relative overflow-x-auto max-md:max-w-80">
          {data && data.length ? (
            <table
              className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400
          
          "
            >
              <thead
                className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400
            
            "
              >
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    First Availble
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rate per note
                  </th>
                </tr>
              </thead>
              <tbody className="relative">
                {data.map(
                  (
                    {
                      full_name,
                      picture,
                      city,
                      collaboration_rate,
                      first_name,
                    },
                    index
                  ) => (
                    <React.Fragment key={index}>
                      <tr
                        className={classNames(
                          { "border-b": index !== expandedRow },
                          "bg-white dark:bg-gray-800 dark:border-gray-700"
                        )}
                      >
                        <td className="px-6 py-4 flex items-center gap-2">
                          {picture ? (
                            <img src={picture} width="35px" height="35px" />
                          ) : (
                            <ProfilePicture
                              name={"first_name"}
                              width="35px"
                              height="35px"
                            />
                          )}
                          {full_name}
                        </td>
                        <td className="px-6 py-4"></td>
                        <td className="px-6 py-4">{city}</td>
                        <td className="px-6 py-4">{collaboration_rate}</td>
                        <td
                          className="px-6 py-4 cursor-pointer"
                          onClick={() =>
                            expandedRow === index
                              ? setExpandedRow(-1)
                              : setExpandedRow(index)
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              expandedRow === index
                                ? faChevronUp
                                : faChevronDown
                            }
                            className="h-4 w-4 text-gray-400  rounded-full"
                            aria-hidden="true"
                          />
                        </td>
                      </tr>
                      {expandedRow === index && (
                        <tr className="border-b">
                          <td colSpan="6">
                            <ProviderDetails data={data[expandedRow]} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <div className="flex py-10 justify-center w-full mt-10">
              No data
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SearchProviderForm;
