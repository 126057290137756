import MyPatientList from "../widgets/MyPatientList";
import CalendarAppointments from "../widgets/CalendarAppointments";
import MyStatesWidget from "./MyStatesWidget";
import ReviewManagementWidget from "./ReviewManagementWidget";
import MedicalProfileWidget from "./MedicalProfileWidget";
import PersonalProfileWidget from "./PersonalProfileWidget";
import CollaborationWidget from "../profile/personal-profile/CollaborationWidget";
import MessageWidget from "./MessageWidget";

export const defaultWidgets = [
  {
    id: 1,
    widget_id: "1",
    position: "1",
  },
  {
    id: 2,
    widget_id: "2",
    position: "2",
  },
];

export const widgets = [
  { id: 1, name: "Calendar", component: <CalendarAppointments /> },
  { id: 2, name: "My Patient", component: <MyPatientList /> },
  { id: 8, name: "Review Management", component: <ReviewManagementWidget /> },
  { id: 7, name: "My Stats", component: <MyStatesWidget /> },
  { id: 3, name: "Personal Profile", component: <PersonalProfileWidget /> },
  { id: 4, name: "Medical Profile", component: <MedicalProfileWidget /> },
  { id: 6, name: "Collaboration", component: <CollaborationWidget /> },

  { id: 5, name: "Communication Centre", component: <MessageWidget /> },
];
