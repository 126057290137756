import classNames from "classnames";
import React, { useEffect, useState } from "react";
import useProviderActions from "../../actions/useProviderActions";

const TimeSlotPicker = (props) => {
  const {
    date,
    formattedSelectedDate,
    data: { bookedSlots, startTime, endTime, slotDuration },
    mySlots = [],
    updateSlots,
  } = props;
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const { bookSlots, cancelBookSlots } = useProviderActions();
  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    const getSlots = () => {
      const slots = generateTimeSlots(startTime, endTime, slotDuration);
      setAvailableTimeSlots(slots);
      const dateToConvert = new Date(date);
      dateToConvert.setDate(dateToConvert.getDate() - 7);

      const convertedDate = dateToConvert.toISOString().split("T")[0];
      setSelectedDate(convertedDate);
    };

    getSlots();
    setSelectedTime(null);
  }, [date]);

  function generateTimeSlots(startTime, endTime, slotDuration) {
    const slots = [];
    let currentTime = parseTime(startTime);
    const endTimeObj = parseTime(endTime);

    while (currentTime < endTimeObj) {
      const slotStart = formatTime(currentTime);
      currentTime.setMinutes(currentTime.getMinutes() + slotDuration);
      const slotEnd = formatTime(currentTime);
      slots.push(`${slotStart}-${slotEnd}`);
    }
    return slots;
  }

  function parseTime(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return new Date(0, 0, 0, hours, minutes);
  }

  function formatTime(date) {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  const configOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const handleBooking = (time) => {
    bookSlots({ time, date: formattedSelectedDate }).then(() => {
      updateSlots();
      setSelectedTime(null);
    });
  };

  const getFormattedDate = (dateString) => {
    const dateParts = dateString.split("-");
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  function toOrdinal(n) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
  const blankSlots = [];
  blankSlots.length = 3 - mySlots.length - (selectedTime ? 1 : 0);
  blankSlots.fill("");
  return (
    <>
      <div className="flex gap-1 overflow-x-auto max-md:flex-wrap max-md:h-48 max-md:my-10">
        {availableTimeSlots.map((timeSlot, index) => {
          const isSelected = selectedTime === timeSlot;
          const isButtonDisabled =
            !date ||
            (bookedSlots &&
              bookedSlots.time_slots &&
              bookedSlots.time_slots.includes(timeSlot)) ||
            mySlots.length > 2;
          return (
            <button
              disabled={isButtonDisabled}
              className={classNames(
                {
                  "bg-select_option": isSelected,
                  "bg-gray_out  text-gray_out_text ": isButtonDisabled,
                },
                "min-w-36 max-md:w-20 flex justify-center border p-4 rounded-2xl"
              )}
              key={index}
              onClick={() => handleTimeSelection(timeSlot)}
            >
              {timeSlot}
            </button>
          );
        })}
      </div>
      <div className="flex justify-start gap-10 w-full py-10">
        <div className="flex w-full gap-2 max-md:flex-col ">
          {mySlots?.map((slot, idx) => {
            return (
              <div
                key={idx}
                className="border py-5 px-5 bg-light_tone_three w-full  rounded-lg flex flex-col gap-2 "
              >
                <p className="">Date: {getFormattedDate(slot.date)}</p>
                <p>Time: {slot.time_slots[0]}</p>
                <div className="flex items-center">
                  <img
                    alt=""
                    src="/assets/images/verified-green-tick.png"
                    className="w-8 h-6"
                  />{" "}
                  {toOrdinal(idx + 1)} Slot Booked
                  <img
                    alt=""
                    loading="lazy"
                    src="/assets/images/document-trash-icon.png"
                    className="w-4 h-5 mx-10 cursor-pointer"
                    onClick={() => {
                      cancelBookSlots();
                    }}
                  />
                </div>
              </div>
            );
          })}
          {mySlots && mySlots.length < 3 && selectedTime ? (
            <div className="border py-5 px-5 bg-light_tone_three w-full rounded-lg flex flex-col gap-2 ">
              <p className="">
                Date: {date.toLocaleDateString("en-US", configOptions)}
              </p>
              <p>Time: {selectedTime}</p>
              <button
                onClick={() => handleBooking(selectedTime)}
                disabled={!selectedTime}
                className={classNames(
                  {
                    "submit-btn-bg-color": selectedTime,
                  },
                  "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  "
                )}
              >
                Book
              </button>
            </div>
          ) : null}
          {blankSlots.map(() => {
            return (
              <div
                key={Math.random()}
                className="border bg-gray_out  text-gray_out_text  w-full py-5 px-5   rounded-lg flex flex-col gap-2 "
              >
                <p className="">Date:</p>
                <p>Time: </p>
                <button
                  disabled={true}
                  className={classNames(
                    `min-w-36 border-2 p-2 w-24 rounded-2xl `
                  )}
                >
                  Book
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TimeSlotPicker;
