const newDate = new Date();
const currentYear = newDate.getFullYear();

export const months = [
  { label: `January ${currentYear}`, value: 1 },
  { label: `February ${currentYear}`, value: 2 },
  { label: `March ${currentYear}`, value: 3 },
  { label: `April ${currentYear}`, value: 4 },
  { label: `May ${currentYear}`, value: 5 },
  { label: `June ${currentYear}`, value: 6 },
  { label: `July ${currentYear}`, value: 7 },
  { label: `August ${currentYear}`, value: 8 },
  { label: `September ${currentYear}`, value: 9 },
  { label: `October ${currentYear}`, value: 10 },
  { label: `November ${currentYear}`, value: 11 },
  { label: `December ${currentYear}`, value: 12 },
  { label: `January ${currentYear + 1}`, value: 1 },
];
export const availableTimeSlots = [
  "00:00-01:00",
  "01:00-02:00",
  "02:00-03:00",
  "03:00-04:00",
  "04:00-05:00",
  "05:00-06:00",
  "06:00-07:00",
  "07:00-08:00",
  "08:00-09:00",
  "09:00-10:00",
  "10:00-11:00",
  "11:00-12:00",
  "12:00-13:00",
  "13:00-14:00",
  "14:00-15:00",
  "15:00-16:00",
  "16:00-17:00",
  "17:00-18:00",
  "18:00-19:00",
  "19:00-20:00",
  "20:00-21:00",
  "21:00-22:00",
  "22:00-23:00",
  "23:00-00:00",
];

export const PREFIXES = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Miss", label: "Miss" },
  { value: "Dr.", label: "Dr." },
  { value: "Prof.", label: "Prof." },
  { value: "Rev.", label: "Rev." },
  { value: "Hon.", label: "Hon." },
  { value: "Sir", label: "Sir" },
  { value: "Lady", label: "Lady" },
];
export const SUFFIXES = [
  { value: "Jr.", label: "Jr." },
  { value: "Sr.", label: "Sr." },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" },
  { value: "PhD", label: "PhD" },
  { value: "MD", label: "MD" },
  { value: "Esq.", label: "Esq." },
];

export const GENDERS = [
  { label: "Prefer Not to Say", value: "Prefer Not to Say" },
  { label: "Women", value: "Women" },
  { label: "Man", value: "Man" },
  { label: "Non-Binary", value: "Non-Binary" },
  { label: "Transfeminine", value: "Transfeminine" },
  { label: "Transmasculine", value: "Transmasculine" },
  { label: "Agender", value: "Agender" },
  { label: "Other", value: "Other" },
];

export const PRO_NOUNS = [
  { label: "Prefer Not to Say", value: "Prefer Not to Say" },
  { label: "He/Him", value: "He/Him" },
  { label: "She/Her", value: "She/Her" },
  { label: "They/Them", value: "They/Them" },
  { label: "Ze/Zem", value: "Ze/Zem" },
  { label: "Others", value: "Others" },
];

export const RELATIONSHIP_STATUS = [
  { label: "Prefer Not to Say", value: "Prefer Not to Say" },
  { label: "Single", value: "Single" },
  { label: "Relationship", value: "Relationship" },
  { label: "Married", value: "Married" },
  { label: "Divorced", value: "Divorced" },
  { label: "Widowed", value: "Widowed" },
  { label: "Widowed", value: "Widowed" },
  { label: "Others", value: "Others" },
];

export const FAITH = [
  { label: "Prefer Not to Say", value: "Prefer Not to Say" },
  { label: "Religious", value: "Religious" },
  { label: "Spiritual", value: "Spiritual" },
  { label: "Agnostic", value: "Agnostic" },
  { label: "Atheist", value: "Atheist" },
];
