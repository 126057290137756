import React, { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AuthContext } from "../../../App";
import { Link } from "react-router-dom";
import DashboardHoc from "./DashboardHoc";
import useProviderActions from "../../../actions/useProviderActions";
import { defaultWidgets, widgets } from "./utils";
import ModalComponent from "../../../components/modals/ModalComponent";
import { BorderAllRounded, Edit } from "@mui/icons-material";
import UpdateWidgetsForm from "./UpdateWidgetsForm";
import ProfilePicture from "../../../components/ProfilePicture";

function PatientDashboard() {
  const { isAuth, userDetails } = useContext(AuthContext);
  const [showCongrats, setShowCongrats] = useState(
    userDetails.welcome_message_displayed != "1"
  );
  const [widgetsList, setWidgetList] = useState([
    {
      id: 1,
      widget_id: "1",
      position: "1",
    },
    {
      id: 1,
      widget_id: "1",
      position: "1",
    },
    {
      id: 2,
      widget_id: "2",
      position: "2",
    },
  ]);
  const { getWidgets, updateWidgets } = useProviderActions();
  const [isUpdateWidgets, setUpdatingWidgets] = useState(false);

  const onUpdateWidget = (data) => {
    const payload = {
      widgets: data.map(({ id, position }) => {
        return {
          widget_id: id,
          position: Number(position.split(" ")[1]),
        };
      }),
      user_id: Number(userDetails.user_id),
    };
    updateWidgets(payload).then(() => {
      window.location.assign("/");
    });
  };
  useEffect(() => {
    getWidgets().then((res) => {
      if (res.status === 200) {
        if (res.data && res.data.length) {
          setWidgetList(res.data.sort((a, b) => a.position - b.position));
        } else {
          setWidgetList(defaultWidgets);
        }
      }
    });
  }, []);
  const getComponent = (id) => {
    const currentWidget = widgets.find((x) => x.id == id);
    if (currentWidget && currentWidget.component) {
      return currentWidget.component;
    } else {
      return null;
    }
  };
  return (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p- h-4/5 overflow-auto max-md:w-full max-md:p-2 ">
        <div
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
          }}
        >
          <div style={{ position: "absolute" }}>
            <ProfilePicture
              width={96}
              height={96}
              classes={"rounded-full"}
              picture={userDetails.picture}
            />
          </div>
          <div style={{ position: "absolute" }}>
            <CircularProgressbar
              value={20}
              styles={buildStyles({
                // pathColor:"#EF8C89"
                // pathColor:"#CDC270"
                rotation: 0.5,
                pathColor: "#D82022",
              })}
            />
          </div>
          <div className="absolute bottom-0 left-5">
            <span
              className="bg-white px-3 pb-2 rounded-t-md rounded-b-md text-body_font_three"
              style={{ color: "#D82022" }}
            >
              20%
            </span>
          </div>
        </div>
        <div className="text-3xl capitalize">
          Welcome,{` ${userDetails.prefix} ${userDetails.first_name}`}
        </div>
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
           
        </div>
        <div className=" flex w-11/12  gap-3 max-md:flex-col">
          {widgetsList && widgetsList[0]
            ? getComponent(widgetsList[0].widget_id)
            : null}
          {widgetsList && widgetsList[1]
            ? getComponent(widgetsList[1].widget_id)
            : null}
        </div>{" "}
        <div className=" flex w-11/12  gap-3 max-md:flex-col">
          {widgetsList && widgetsList[2]
            ? getComponent(widgetsList[2].widget_id)
            : null}
          {widgetsList && widgetsList[3]
            ? getComponent(widgetsList[3].widget_id)
            : null}
        </div>
        {/* {widgetsList.includes(widgetNames.My_Patient_List) ? (
            <MyPatientList />
          ) : null}
          {widgetsList.includes(widgetNames.Calendar_Appointment_EA) ? (
            <CalendarAppointments />
          ) : null} */}
        {/* {widgetsList.includes(widgetNames.Calendar_Appointment_EA) ? (
            <CalendarAppointments />
          ) : null} */}
        {/* // firstClasses={`flex w-11/12 gap-10 h-96 max-md:flex-col max-md:w-full `} */}
        {/* <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full max-md:h-96">
              <div className="text-subtitle_one">Calendar/ Appointment EA</div>
          </div> */}
        <div className=" flex w-11/12 gap-10 max-md:w-full ">
          <div className="flex w-full mr-10 gap-10 h-32 ">
            <div
              onClick={() => setUpdatingWidgets(true)}
              className="bg-gray_out gap-5 items-center 
              cursor-pointer
              shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full "
            >
              {/* <img src="add-icon" alt="add-icon" /> */}
              <Edit />
              <div className="text-subtitle_one">Update Widgets</div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        isOpen={isUpdateWidgets}
        className={
          "w-2/3 absolute bg-complementary_accent_one border-none  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <UpdateWidgetsForm
              close={() => {
                setUpdatingWidgets(false);
              }}
              onUpdate={onUpdateWidget}
            />
          </div>
        }
      />
    </DashboardHoc>
  );
}

export default PatientDashboard;
