import React, { useState } from "react";
import PaymentOptionsModal from "../../components/modals/PaymentOptionsModal";
const Card = ({
  name,
  rate,
  description,
  helpingRate = " Pay Period*",
  handleCardSelect,
}) => {
  return (
    <div
      onClick={() => {
        handleCardSelect(name);
      }}
      className="w-72 max-md:w-full h-96 rounded-3xl border-color-1 border-2 flex flex-col items-center shadow-lg cursor-pointer"
    >
      <div className="my-10 font-extrabold text-2xl">{name}</div>
      <div
        //   className="w-full"
        className="membership-card-btn-bg w-full  flex items-center justify-center "
      >
        <button className="max-w-52 h-14 cursor-default  text-complementary_accent_one bg-dark_tone rounded-md shadow-md flex justify-center items-start align-middle p-3">
          <div className="font-bold " style={{ fontSize: "24px" }}>
            ${rate}/
          </div>
          <div className="max-w-16" style={{ fontSize: "12px" }}>
            {helpingRate}
          </div>
        </button>
      </div>
      <div className="w-72 h-64  max-md:w-full rounded-b-3xl px-10 text-center bg-[#223f9914] font-light text-body_font_two ">
        <div className="py-2">{description}</div>
      </div>
    </div>
  );
};
function PlanAndPaymentsUnAuth() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState("Balance");
  const handleCardSelect = (name) => {
    setSelectedCard(name);
    setModalOpen(true);
  };
  return (
    <section
      className="my-4 text-dark_tone capitalize"
      aria-label="Plan And Payments"
      id="Plan And Payments"
    >
      <PaymentOptionsModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
      />
      <div className="bg-complementary_accent_one p-10  max-md:p-2  rounded-2xl  shadow-lg my-2 w-full">
        <div className="text-2xl text-color-1 font-semibold  my-2">
          Membership
        </div>
        <div className="text-subtitle_one pb-2 max-md:px-2 ">
          Each plan is designed to cater to different needs and budgets.
          <strong>
            {" "}
            Our payment plans are based on a two-week pay period*.
          </strong>
        </div>
        <div className="flex justify-between my-2 flex-wrap gap-2 ">
          <Card
            handleCardSelect={handleCardSelect}
            name={"Essential"}
            rate={"16.99"}
            description="This plan is a great starting point for those seeking occasional consultations. However, consultations with this plan have a higher consultation fee."
          />
          <Card
            handleCardSelect={handleCardSelect}
            name={"Balance"}
            rate={"19.99"}
            description="This plan offers a good balance between affordability and frequent consultations with a moderate consultation fee."
          />
          <Card
            handleCardSelect={handleCardSelect}
            name={"Freedom"}
            rate={"24.99"}
            description="This plan is ideal for those seeking regular consultations and comes with the lowest consultation fee per session."
          />
          <Card
            handleCardSelect={handleCardSelect}
            name={"Trial"}
            rate={"0"}
            description={`If you'd prefer to try out our services. Before committing to a plan. You may select "No subscription" for your assessment.`}
            helpingRate={"for 1st Appointment"}
          />
        </div>
      </div>
    </section>
  );
}

export default PlanAndPaymentsUnAuth;
