import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
// const CryptoJS = require("crypto-js");
import Select from "react-select";
import FormInputComponent from "../../containers/forms/registration-and-verification/FormInputComponent";
import useUserActions from "../../actions/useUserActions";
import FormButtonComponent from "../../containers/forms/registration-and-verification/FormButtonComponent";
import {
  PREFIXES,
  PRO_NOUNS,
  SUFFIXES,
} from "../../containers/forms/registration-and-verification/utils";
import StatePicker from "../../containers/forms/registration-and-verification/StatePicker";

function PersonalDetailsForm({
  onSubmit,
  isEditing,
  setIsEditing,
  handleChange,
  userDetails,
}) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
  } = useForm();
  const { getStateByZip } = useUserActions();

  if (userDetails)
    return (
      <>
        {/* <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl my-4 shadow-lg">
          <div className="text-body_font_two pb-2 max-md:px-2 ">
            Confirm Name
          </div>
          <div
            className="
    flex gap-5 justify-start max-md:px-2  max-md:flex-col flex-wrap w-full"
          >
            <div>
              <label
                className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
                htmlFor={"prefix"}
              >
                Prefix
              </label>

              <Select
                options={PREFIXES}
                className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
                id="prefix"
                name="prefix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("prefix", e.value)}
                defaultValue={PREFIXES.find(
                  (x) => x.value === userDetails.prefix
                )}
              />
            </div>
            <FormInputComponent
              register={register}
              inputFor={"firstName"}
              isDisabled={!isEditing}
              inputName="first_name"
              inputLabel={"First Name"}
              inputType="text"
              className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="firstName"
              inputAutocomplete="firstName"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails.first_name}
            />{" "}
            <FormInputComponent
              isDisabled={!isEditing}
              register={register}
              inputFor={"midName"}
              inputName="middle_name"
              inputLabel={"Middle Name"}
              inputType="text"
              className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="midName"
              inputAutocomplete="midName"
              // validations={{ required: false }}
              inputPlaceholderP=""
              defaultValue={userDetails.middle_name}
            />{" "}
            <FormInputComponent
              register={register}
              inputFor={"lastName"}
              inputName="last_name"
              inputLabel={"Last Name"}
              inputType="text"
              className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="lastName"
              inputAutocomplete="lastName"
              // validations={{ required: false }}
              defaultValue={userDetails.last_name}
              inputPlaceholder=""
              isDisabled={!isEditing}
            />
            <div>
              <label
                className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
                htmlFor={"suffix"}
              >
                Suffix
              </label>
              <Select
                options={SUFFIXES}
                className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
                id="suffix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("suffix", e.value)}
                defaultValue={SUFFIXES.find(
                  (x) => x.value === userDetails.suffix
                )}
              />
            </div>
          </div>

          <div className="mt-2  flex justify-start gap-3 max-md:flex-col max-md:px-2">
            <FormInputComponent
              // register={register}
              inputFor={"email"}
              inputName="email"
              inputLabel={"Email id"}
              inputType="email"
              className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="email"
              inputAutocomplete="email"
              // validations={{ required: true }}
              inputPlaceholderP=""
              defaultValue={userDetails.email}
              isDisabled={true}
            />{" "}
            <FormInputComponent
              register={register}
              isDisabled={!isEditing}
              inputFor={"phone"}
              inputName="phone_number"
              inputLabel={"Phone"}
              inputType="text"
              className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="phone"
              inputAutocomplete="phone"
              // validations={{ required: true }}
              inputPlaceholderP=""
              defaultValue={userDetails.phone_number}
            />
            <div className="w-52">
              <div>Pronoun</div>
              <div>
                <Select
                  classNames={{
                    control: () =>
                      classNames(
                        {},
                        "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize "
                      ),
                  }}
                  name="pronoun"
                  defaultValue={PRO_NOUNS.find(
                    (x) => x.value === userDetails.pronoun
                  )}
                  onChange={(e) => handleChange("pronoun", e.value)}
                  options={PRO_NOUNS}
                />
              </div>
            </div>
            <FormInputComponent
              register={register}
              inputFor={"birthdate"}
              inputName="birthdate"
              inputLabel={"Date of Birth"}
              inputType="date"
              className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="birthdate"
              inputAutocomplete="birthdate"
              inputPlaceholderP=""
              onChange={(e) => handleChange("birthdate", e.value)}
              defaultValue={userDetails.birthdate}
            />{" "}
          </div>

          <div className="flex gap-5 mt-10 flex-wrap w-2/3">
            <FormInputComponent
              register={register}
              inputFor={"address_line1"}
              inputName="address_line1"
              inputLabel={"Address Line 1"}
              inputType="text"
              className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="address_line1"
              inputAutocomplete="address_line1"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["address_line1"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"address_line2"}
              inputName="address_line2"
              inputLabel={"Address Line 2"}
              inputType="text"
              className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="address_line2"
              inputAutocomplete="address_line2"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["address_line2"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"city"}
              inputName="city"
              inputLabel={"City"}
              inputType="text"
              className="block w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 max-md:w-full"
              inputId="city"
              inputAutocomplete="city"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["city"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"zip_code"}
              inputName="zip_code"
              inputLabel={"Zip Code"}
              inputType="text"
              // inputPattern="[0-9]"
              className="block w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="zip_code"
              inputAutocomplete="zip_code"
              // validations={{ required: true }}
              inputPlaceholder=""
              handleChange={(e) => handleZIPchange(e.target.value)}
              defaultValue={userDetails["zip_code"]}
              isDisabled={!isEditing}
            />
            <div>
              <StatePicker
                onChange={(val) => handleChange("state", val)}
                isDisabled={!isEditing}
              />
            </div>
          </div>
          <br />

          <div className="mt-2 flex gap-3 max-md:px-2 ">
            <FormButtonComponent
              ButtonType="button"
              onClick={() => setIsEditing(true)}
              className="text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
              ButtonValue="Edit"
            ></FormButtonComponent>
            {isEditing ? (
              <FormButtonComponent
                ButtonType="submit"
                className={classNames(
                  "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  submit-btn-bg-color"
                )}
                ButtonValue="Confirm"
              ></FormButtonComponent>
            ) : (
              <FormButtonComponent
                ButtonType="button"
                className={classNames(
                  "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  "
                )}
                ButtonValue="Confirm"
              ></FormButtonComponent>
            )}
          </div>
          <div></div>
        </div> */}
        <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  shadow-lg">
          <div className="text-body_font_two pb-2 max-md:px-2 ">
            Confirm Name
          </div>
          <div
            className="
        flex gap-5 justify-start max-md:px-2  max-md:flex-col flex-wrap w-full"
          >
            <div>
              <label
                className=" text-sm font-medium leading-6 text-mid_tone_one mb-2"
                htmlFor={"prefix"}
              >
                Prefix
              </label>

              <Select
                options={PREFIXES}
                className={
                  "w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 my-2"
                }
                id="prefix"
                name="prefix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("prefix", e.value)}
                defaultValue={PREFIXES.find(
                  (x) => x.value === userDetails.prefix
                )}
              />
            </div>
            <FormInputComponent
              register={register}
              inputFor={"firstName"}
              isDisabled={!isEditing}
              inputName="first_name"
              inputLabel={"First Name"}
              inputType="text"
              className={classNames(
                {
                  "border-error": errors.first_name,
                },
                "  w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              )}
              inputId="firstName"
              inputAutocomplete="firstName"
              validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails.first_name}
            />{" "}
            <FormInputComponent
              isDisabled={!isEditing}
              register={register}
              inputFor={"midName"}
              inputName="middle_name"
              inputLabel={"Middle Name"}
              inputType="text"
              className=" w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="midName"
              inputAutocomplete="midName"
              // validations={{ required: false }}
              inputPlaceholderP=""
              defaultValue={userDetails.middle_name}
            />{" "}
            <FormInputComponent
              register={register}
              inputFor={"lastName"}
              inputName="last_name"
              inputLabel={"Last Name"}
              inputType="text"
              className={classNames(
                {
                  "border-error": errors.last_name,
                },
                "w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              )}
              inputId="lastName"
              inputAutocomplete="lastName"
              validations={{ required: true }}
              defaultValue={userDetails.last_name}
              inputPlaceholder=""
              isDisabled={!isEditing}
            />
            <div>
              <label
                className=" text-sm font-medium leading-6 text-mid_tone_one mb-2"
                htmlFor={"suffix"}
              >
                Suffix
              </label>
              <Select
                options={SUFFIXES}
                className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 my-2"
                id="suffix"
                placeholder=""
                isDisabled={!isEditing}
                onChange={(e) => handleChange("suffix", e.value)}
                defaultValue={SUFFIXES.find(
                  (x) => x.value === userDetails.suffix
                )}
              />
            </div>
            {/* </div> */}
            {/* <div className="mt-2  flex justify-start gap-3 max-md:flex-col max-md:px-2"> */}
            <div>
              <div className="flex w-full gap-3 flex-wrap">
                <FormInputComponent
                  inputFor={"email"}
                  inputName="email"
                  inputLabel={"Email id"}
                  inputType="email"
                  className=" w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
                  inputId="email"
                  inputAutocomplete="email"
                  inputPlaceholderP=""
                  defaultValue={userDetails.email}
                  isDisabled={true}
                />{" "}
                <FormInputComponent
                  register={register}
                  isDisabled={!isEditing}
                  inputFor={"phone"}
                  inputName="phone_number"
                  inputLabel={"Phone"}
                  inputType="text"
                  className={classNames(
                    {
                      "border-error": errors.phone_number,
                    },
                    " w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
                  )}
                  inputId="phone"
                  inputAutocomplete="phone"
                  validations={{ required: true }}
                  inputPlaceholderP=""
                  defaultValue={userDetails.phone_number}
                />
              </div>
            </div>
            <div className="w-52">
              <div>Pronoun</div>
              <div>
                <Select
                  classNames={{
                    control: () =>
                      classNames(
                        {},
                        "mb-10 mt-2 h-10 rounded-xl select-box-upload-doc capitalize "
                      ),
                  }}
                  name="pronoun"
                  defaultValue={PRO_NOUNS.find(
                    (x) => x.value === userDetails.pronoun
                  )}
                  onChange={(e) => handleChange("pronoun", e.value)}
                  options={PRO_NOUNS}
                />
              </div>
            </div>
            <FormInputComponent
              register={register}
              inputFor={"birthdate"}
              inputName="birthdate"
              inputLabel={"Date of Birth"}
              inputType="date"
              className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="birthdate"
              inputAutocomplete="birthdate"
              inputPlaceholderP=""
              onChange={(e) => handleChange("birthdate", e.value)}
              defaultValue={userDetails.birthdate}
            />
            {/* <div className="flex gap-5 mt-10 flex-wrap w-2/3"> */}
            <FormInputComponent
              register={register}
              inputFor={"address_line1"}
              inputName="address_line1"
              inputLabel={"Address Line 1"}
              inputType="text"
              className=" w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="address_line1"
              inputAutocomplete="address_line1"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["address_line1"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"address_line2"}
              inputName="address_line2"
              inputLabel={"Address Line 2"}
              inputType="text"
              className=" w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              inputId="address_line2"
              inputAutocomplete="address_line2"
              // validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["address_line2"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"city"}
              inputName="city"
              inputLabel={"City"}
              inputType="text"
              className={classNames(
                {
                  "border-error": errors.city,
                },
                " w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 max-md:w-full"
              )}
              inputId="city"
              inputAutocomplete="city"
              validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["city"]}
              isDisabled={!isEditing}
            />{" "}
            <div>
              <StatePicker
                onChange={(val) => handleChange("state", val)}
                isDisabled={!isEditing}
              />
            </div>
            <FormInputComponent
              register={register}
              inputFor={"zip_code"}
              inputName="zip_code"
              inputLabel={"Zip Code"}
              inputType="text"
              // inputPattern="[0-9]"
              className={classNames(
                {
                  "border-error": errors.zip_code,
                },
                " w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
              )}
              inputId="zip_code"
              inputAutocomplete="zip_code"
              validations={{ required: true }}
              inputPlaceholder=""
              handleChange={(e) => handleZIPchange(e.target.value)}
              defaultValue={userDetails["zip_code"]}
              isDisabled={!isEditing}
            />
            <FormInputComponent
              register={register}
              inputFor={"ssn"}
              inputName="ssn"
              inputLabel={"Social Security Number"}
              inputType="text"
              className={classNames(
                {
                  "border-error": errors.ssn,
                },
                " w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 max-md:w-full"
              )}
              inputId="ssn"
              inputAutocomplete="ssn"
              validations={{ required: true }}
              inputPlaceholder=""
              defaultValue={userDetails["ssn"]}
              isDisabled={!isEditing}
            />
          </div>
          <br />

          <div className="mt-2 flex gap-3 max-md:px-2 ">
            {/* <FormButtonComponent
            ButtonType="button"
            onClick={() => setIsEditing(true)}
            className="text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
            ButtonValue="Edit"
          ></FormButtonComponent> */}
            {isEditing ? (
              <FormButtonComponent
                ButtonType="submit"
                isDisabled={updatingPI}
                className={classNames(
                  { "submit-btn-bg-color text-light_tone_one": !updatingPI },
                  "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11   "
                )}
                ButtonValue={updatingPI ? "Updating..." : "Confirm"}
              >
                <ClipLoader
                  color={"#000000"}
                  loading={updatingPI}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </FormButtonComponent>
            ) : (
              <FormButtonComponent
                ButtonType="button"
                className={classNames(
                  "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  "
                )}
                ButtonValue="Confirm"
              ></FormButtonComponent>
            )}
          </div>
        </div>
      </>
    );
  return null;
}

export default PersonalDetailsForm;
