import React from "react";
import { Link } from "react-router-dom";

function MyStatesWidget() {
  const item = [1, 2, 3, 4];

  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96 relative">
      <div className="text-subtitle_one">My Stats</div>
      <div className="h-96">Some data</div>

      <div className="absolute right-3 bottom-1">
        <div className="flex justify-end my-1">
          <Link
            to="/provider/care-collaboration/details"
            className="flex justify-end mb-2"
          >
            <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
              View Details
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyStatesWidget;
