import React, { useContext, useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import Select from "react-select";
import FormButtonComponent from "./FormButtonComponent";
import classNames from "classnames";
import ModalComponent from "../../../components/modals/ModalComponent";
import useUserActions from "../../../actions/useUserActions";
import { Edit } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
function ProviderCategory() {
  const {
    update: updatePersonalDetails,
    getUser,
    editCategory,
  } = useUserActions();
  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const [categoryOptions, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isEditingCat, setIsEditingCat] = useState(false);
  const [showEditConfirmModel, setShowEditConfirmModel] = useState(false);

  const initCatData = {
    label: "Select Provider",
    value: "-1",
  };
  if (userDetails && userDetails.provider_details) {
    initCatData["value"] = userDetails.provider_details.category_id;
    initCatData["label"] = userDetails.provider_details.category_name;
  }

  const [selectedCategory, setSelectedCategory] = useState(initCatData);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {})
      .then((res) => res.json())
      .then((data) => {
        setData(
          data.map((category) => ({
            label: category.category_name,
            value: category.category_id,
          }))
        );
        getUser();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
  };
  const [requestSave, setReqSave] = useState(false);
  const handleSaveClick = () => {
    setReqSave(true);
  };
  const editCat = () => {
    setIsEditingCat(true);
    editCategory().finally(() => {
      setIsEditingCat(false);
    });
  };
  const saveTodb = () => {
    if (selectedCategory.value)
      updatePersonalDetails({ category: selectedCategory.value }).then(
        (res) => {
          if (
            res.provider_details &&
            res.provider_details.category_id == selectedCategory.value
          ) {
            window.location.assign("/");
          }
        }
      );

    setReqSave(false);
  };

  return (
    <div className="py-10  max-md:px-10 ">
      <div className="text-2xl ">Provider Category</div>

      <ModalComponent
        isOpen={requestSave}
        className={
          "w-2/3 absolute bg-complementary_accent_one  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">Are You Sure</div>
            <div className="text-subtitle_one w-2/3 text-center">
              You have Selected your self as{" "}
              <span className="text-2xl underline font-bold">
                {selectedCategory.label}
              </span>
              . You can not change it again
            </div>
            <div className="flex gap-2">
              <button
                className="border border-mid_tone_three btn py-3 px-5 rounded-xl w-72
          "
                onClick={() => {
                  saveTodb();
                }}
              >
                Continue as &nbsp;{selectedCategory.label}
              </button>
              <button
                className="submit-btn-bg-color text-light_tone_one  border btn  py-3 px-5 rounded-xl w-36
          "
                onClick={() => {
                  setReqSave(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      />
      <ModalComponent
        isOpen={showEditConfirmModel}
        className={
          "w-2/3 absolute bg-complementary_accent_one  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">Are You Sure </div>
            <div className="text-subtitle_one w-2/3 text-center capitalize">
              Change category action will remove all uploaded documents
              Permanently.
            </div>
            <div className="flex gap-2">
              <button
                className="border border-error btn py-3 px-5 flex gap-1 justify-center items-center rounded-xl w-72
          "
                disabled={isEditingCat}
                onClick={() => {
                  editCat();
                }}
                // onClick={() => {
                // }}
              >
                {isEditingCat ? (
                  <>
                    {" "}
                    Updating...
                    <ClipLoader
                      color={"#00000"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </>
                ) : (
                  "I Agree"
                )}
              </button>
              <button
                className="submit-btn-bg-color text-light_tone_one  border btn  py-3 px-5 rounded-xl w-36
          "
                disabled={isEditingCat}
                onClick={() => {
                  setShowEditConfirmModel(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      />
      {isLoading ? (
        <Skeleton width={"100%"} height={100} />
      ) : (
        <div className="bg-complementary_accent_one p-10 max-md:py-6 max-md:px-1 rounded-2xl shadow-lg">
          {userDetails?.provider_details?.category_id > -1 &&
          userDetails?.provider_details?.category_name ? (
            <span className="text-body_font_one">
              {userDetails?.provider_details?.category_name} &nbsp;{" "}
              <button onClick={() => setShowEditConfirmModel(true)}>
                <Edit />
              </button>
            </span>
          ) : (
            <>
              <div className="flex gap-2 w-full items-center">
                <Select
                  classNames={{
                    control: (state) => "w-96 h-10 rounded-xl",
                  }}
                  placeholder="Provide Category"
                  options={categoryOptions}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                />
                <FormButtonComponent
                  ButtonType="button"
                  isDisabled={selectedCategory.value < 0}
                  onClick={handleSaveClick}
                  className={classNames(
                    {
                      "submit-btn-bg-color": selectedCategory.value > 0,
                    },
                    `text-body_font_two  w-36  hover:border-transparent  border 
        rounded-md h-12 text-light_tone_one bg-mid_tone_two `
                  )}
                  ButtonValue={"Save"}
                ></FormButtonComponent>
              </div>
              <small className="text-complementary_accent_three">
                *Please Select Provider category carefully
              </small>
              <br />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ProviderCategory;
