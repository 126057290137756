import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../../../../components/ProfilePicture";
import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { Divider, Skeleton } from "@mui/material";

function MyPatientList() {
  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const { getMyPatients } = useProviderActions();
  const [patients, setList] = React.useState([]);
  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyPatients()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96 relative">
      <div className="text-subtitle_one">My Patient List</div>
      <div className="my-4 w-full">
        <div className="flex gap-2  items-center ">
          <div className="flex flex-col gap-2 h-60 overflow-auto w-full">
            <div className="text-gray_out_text  font-normal flex justify-between ">
              <div>Name</div>
              <div>
                Appointment{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="h-4 w-4 text-gray-400 "
                  aria-hidden="true"
                />
              </div>
            </div>
            {isLoading ? (
              <>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>

                <div className="my-2">
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </div>
              </>
            ) : null}
            {patients.map((patient, idx) => {
              return (
                <div key={patient.id}>
                  <div className="font-normal my-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <ProfilePicture picture={patient.picture} />
                        {patient.name}
                      </div>
                      <div className="flex flex-col justify-start items-start ">
                        <div>{patient.next_appointment_date}</div>
                        <div className="text-gray_out_text   ">
                          {patient.next_appointment_time}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>

        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <div className="absolute right-3 bottom-1">
          <div className="flex justify-end mb-1">
            <Link
              to="/provider/care-collaboration/patient-list"
              className="flex justify-end mb-2"
            >
              <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPatientList;
