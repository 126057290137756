import moment from "moment";
import React from "react";

function ExperienceAndConsultationHours({
  consultationHours,
  totalExperience,
}) {
  const weekdays = consultationHours.filter((x) => x.type == "weekday");
  const weekends = consultationHours.filter((x) => x.type == "weekend");

  return (
    <section className="p-10 max-md:p-4">
      <div className="w-full flex justify-between max-md:flex-col">
        <div className="w-1/2 text-body_font_two font-medium pr-16 max-md:w-full max-md:pr-0 max-md:flex max-md:items-center">
          <span className="text-color-1">Total Experience:</span>
          <div className="pr-16 flex gap-2 text-body_font_three mt-4 max-md:mt-1">
            <span>{totalExperience} years</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExperienceAndConsultationHours;
