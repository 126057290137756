import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import AppointmentList from "./AppointmentList";
import { AuthContext } from "../../../../App";
import useProviderActions from "../../../../actions/useProviderActions";
import { Skeleton } from "@mui/material";
import moment from "moment";

function CalendarAppointments() {
  const today = new Date();

  const { userDetails } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(true);
  const { getMyAppointments } = useProviderActions();
  const [appointments, setAppointmentList] = React.useState([]);

  const updateData = (date) => {
    setLoading(true);

    getMyAppointments(date)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setAppointmentList(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getMyAppointments()
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setAppointmentList(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  return (
    <div className="bg-white    shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-full relative">
      <div className="text-subtitle_one">Calendar/ Appointment EA</div>
      <div className="my-4">
        <div className="flex gap-2   items-center max-md:flex-col">
          <DatePicker
            startDate={Date.now()}
            inline
            className=""
            showLeadingZeros={true}
            value={today}
            onChange={(e) => updateData(moment(e).format("YYYY-MM-DD"))}
            minDate={new Date()}
            dateFormat="MMMM, yyyy"
          />

          <img
            className="h-48 w-0.5 max-md:hidden"
            src="/assets/images/login-img-3-1.png"
          ></img>
          <div
            className="flex w-96 flex-col gap-2 h-60 overflow-auto max-md:w-full 
                max-md:px-2 max-md:max-36 max-md:overflow-auto"
          >
            {isLoading ? (
              <>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={220} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={220} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={220} height={20} />
                </div>{" "}
                <div className="my-2">
                  <Skeleton variant="rectangular" width={220} height={20} />
                </div>
                <div className="my-2">
                  <Skeleton variant="rectangular" width={220} height={20} />
                </div>
              </>
            ) : (
              <AppointmentList appointments={appointments} />
            )}
          </div>
        </div>

        <br />
        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <div className="absolute right-3 bottom-1">
          <div className="flex justify-end mb-1">
            <Link to="/provider/scheduling-communication/calender">
              <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarAppointments;
