import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export default function AppointmentList({ appointments }) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {appointments.map((listItem, key) => (
        <div key={key}>
          <ListItem className="flex w-full items-end">
            <ListItemText
              primary={`${listItem.start_time} - ${listItem.end_time}`}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: "text.primary", display: "inline" }}
                  >
                    {listItem.title}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />
        </div>
      ))}
    </List>
  );
}
