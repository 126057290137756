import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ProfilePicture from "../../../../components/ProfilePicture";
import useCollabActions from "../../../../actions/useCollabActions";
import { Divider, Skeleton } from "@mui/material";

function NewCollaboratorList() {
  const [requests, setRequestList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { getCollabsRequests, rejectCollabRequest, acceptCollabRequest } =
    useCollabActions();

  const getData = () => {
    setLoading(true);

    getCollabsRequests()
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setRequestList(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const accept = (id) => {
    setLoading(true);
    acceptCollabRequest(id).then(() => {
      getData();
    });
  };
  const reject = (id) => {
    setLoading(true);
    rejectCollabRequest(id).then(() => {
      getData();
    });
  };

  if (loading) {
    return (
      <div className="bg-white w-full ease-in duration-300  rounded-lg shadow-lg">
        <div className="text-subtitle_one px-4 py-4">
          {" "}
          New Collaboration Requests
        </div>
        <div className="relative overflow-x-auto max-md:max-w-80 px-10">
          <Skeleton width={"100%"} height={70} />
          <Skeleton width={"100%"} height={70} />
        </div>
      </div>
    );
  }

  return (
    <div
      id={"collab-requests"}
      className="bg-white w-full ease-in duration-300  rounded-lg shadow-lg"
    >
      <div className="text-subtitle_one px-4 py-4">
        New Collaboration Requests
      </div>
      <div className="relative overflow-x-auto max-md:max-w-80">
        {requests && requests.length ? (
          <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Message
                </th>
                <th scope="col" className="px-6 py-3">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="border">
              {requests.map(
                ({ picture, first_name, full_name, collaboration_id }) => {
                  return (
                    <tr className="bg-white  border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4 flex items-center gap-2">
                        {picture ? (
                          <img src={picture} width="35px" height="35px" />
                        ) : (
                          <ProfilePicture
                            name={first_name}
                            width="35px"
                            height="35px"
                          />
                        )}
                        {full_name}
                      </td>
                      <td className="px-6 ">
                        <img
                          src={"/assets/icons/provider/new-email.png"}
                          className="w-7 h-7"
                        />
                      </td>{" "}
                      <td className="px-6 py-4">
                        <span
                          onClick={() => {
                            accept(collaboration_id);
                          }}
                          className="flex items-center gap-16 cursor-pointer"
                        >
                          <span className="flex items-center gap-1 text-mid_tone_three ">
                            <img
                              alt=""
                              loading="lazy"
                              src="/assets/icons/provider/accept-req-icon.png"
                              className="w-6 h-6"
                            />
                            Accept Request
                          </span>
                        </span>
                      </td>{" "}
                      <td className="px-6 py-4">
                        <span
                          onClick={() => {
                            reject(collaboration_id);
                          }}
                          className="cursor-pointer flex items-center gap-16 text-complementary_accent_three"
                        >
                          <span className="flex items-center gap-2">
                            <img
                              alt=""
                              loading="lazy"
                              src="/assets/icons/provider/reject-req-icon.png"
                              className="w-6 h-6"
                            />
                            Deny Request
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <div className="flex w-ful justify-center py-10">
            No New Requests Found
          </div>
        )}
      </div>
    </div>
  );
}

export default NewCollaboratorList;
