import { useContext } from "react";
import { AuthContext } from "../App";
import { AUTH_HEADER, baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import { isAuthorize } from "./useUserActions";

function useCollabActions() {
  // const { userDetails, updateUserContext } = useContext(AuthContext);
  const headers = AUTH_HEADER;

  const getPendingNotes = async () => {
    try {
      return await axios.get(
        `${baseURL}/collaborations?request=accepted`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getExitingCollabs = async () => {
    try {
      return await axios.get(
        `${baseURL}/collaborations?request=accepted`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const getCollabsRequests = async () => {
    try {
      return await axios.get(`${baseURL}/collaborations?request=new`, headers);
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };
  const searchProviderForCollab = async (queryString) => {
    try {
      return await axios.get(
        `${baseURL}/collaborations/users?search=${queryString}`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  const terminateCollab = (reqId) => {
    return axios
      .post(
        `${baseURL}/collaborations/terminate_request/${reqId}`,
        {
          status: "terminated",
        },
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const acceptCollabRequest = (reqId) => {
    return axios
      .post(
        `${baseURL}/collaborations/accept_request/${reqId}`,
        {
          status: "accepted",
        },
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const rejectCollabRequest = (reqId) => {
    return axios
      .post(
        `${baseURL}/collaborations/reject_request/${reqId}`,
        {
          status: "rejected",
        },
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const sendCollabRequest = (drId) => {
    return axios
      .post(
        `${baseURL}/collaborations/send_request`,
        {
          dr_user_id: drId,
        },
        headers
      )
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  return {
    terminateCollab,
    getExitingCollabs,
    searchProviderForCollab,
    sendCollabRequest,
    getExitingCollabs,
    getCollabsRequests,
    rejectCollabRequest,
    acceptCollabRequest,
    getPendingNotes,
  };
}

export default useCollabActions;
