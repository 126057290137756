import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Remove, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
function ProfessionalMemberships() {
  const [membershipData, setMembershipData] = useState([]);
  const [saving, setSaving] = useState(true);

  const { saveProfessionalMembership, getProfessionalMembership, deletePfM } =
    useProviderActions();
  const { userDetails } = useContext(AuthContext);
  const getData = () => {
    setSaving(true);
    getProfessionalMembership(userDetails.user_id).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setMembershipData(res.data);
          setSaving(false);
        }
      }
    });
  };
  useEffect(() => {
    if (userDetails && userDetails.user_id) getData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const onAdd = (data) => {
    const payload = {
      memberships: [data].map(
        ({ id, institution_name, start_year, end_year }) => ({
          id,
          institution_name,
          start_year,
          end_year,
        })
      ),
    };
    saveProfessionalMembership(payload).then(() => {
      getData();
    });
  };

  const onSubmit = (data) => {
    reset();
    setSaving(true);

    onAdd(data);
  };

  const removeItem = (id) => {
    setSaving(true);
    deletePfM(id).then(() => {
      getData();
    });
  };

  return (
    <div className="my-4  px-10 max-md:p-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Professional Memberships
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
            <div className="my-4 flex justify-between items-center max-md:flex-col max-md:gap-3 gap-2">
              <input
                placeholder="Name of the Institution"
                {...register("institution_name", { required: true })}
                className="w-96 border p-3 rounded-md  max-md:w-80"
              />
              <span>From</span>
              <input
                placeholder="From"
                {...register("start_year", { required: true })}
                type="number"
                className="w-52 border p-3 rounded-md  max-md:w-80"
              />
              <span>To</span>
              <input
                type="number"
                placeholder="To"
                {...register("end_year", { required: true })}
                className="w-52 border p-3 rounded-md  max-md:w-80"
              />

              <button
                type={"submit"}
                className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg"
              >
                {saving ? "Saving.." : "Save"}
              </button>
            </div>
          </div>
        </form>
        <div className=" bg-gray_out rounded-lg">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">
                  <strong>Institute Name</strong>
                </TableCell>
                <TableCell component="th">
                  <strong>Years of Association</strong>
                </TableCell>

                <TableCell component="th">
                  <strong>Remove</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membershipData.map((mem, idx) => {
                return (
                  <TableRow key={mem.id}>
                    <TableCell>{mem.institution_name}</TableCell>
                    <TableCell>{`${mem.start_year}-${mem.end_year}`}</TableCell>
                    <TableCell>
                      <button>
                        <RemoveCircleOutlineOutlined
                          color="error"
                          onClick={() => removeItem(mem.id)}
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* <div className="text-body_font_two flex font-semibold pl-10">
          <span className="w-[600px]"></span>
          <span className="w-[600px]"></span>
          <span className="w-96">Action</span>
        </div> */}
          {saving ? (
            <>
              <div className="">
                <Skeleton width={"100%"} height={50} />
              </div>
            </>
          ) : membershipData && membershipData.length == 0 ? (
            <div className="flex justify-center w-full py-4">No Data</div>
          ) : null}
        </div>
      </section>
    </div>
  );
}

export default ProfessionalMemberships;
