import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";

function ProfessionalExperience() {
  const [exp, setExp] = useState({
    employers: [],
    total_experience: 0,
  });
  const [newData, setNewData] = useState([]);

  const { saveExp, getExp, deleteEdu } = useProviderActions();
  const [isloading, setDeleting] = useState(false);

  const { userDetails } = useContext(AuthContext);
  const getData = () => {
    setDeleting(true);
    getExp(userDetails.user_id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            //TODO as response incorrect
            setExp(res.data);
          }
        }
      })
      .finally(() => {
        setDeleting(false);
      });
  };
  useEffect(() => {
    if (userDetails && userDetails.user_id) getData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const [saving, setSaving] = useState(false);
  const [isPresent, setPresent] = useState(false);
  const onSubmit = (data) => {
    onSave(data);
    reset();
  };
  const date = new Date();
  const currentYear = date.getFullYear();
  const onSave = (data) => {
    setSaving(true);
    setPresent(false);
    const payload = { experiences: [data] };
    saveExp(payload)
      .then((x) => {
        setNewData([]);
        getData();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const removeItem = (id) => {
    setDeleting(true);
    deleteEdu(id)
      .then(() => {
        getData();
      })
      .finally(() => {
        setDeleting(false);
      });
  };
  return (
    <div className="my-4  px-10">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10">
        <div className="text-xxl font-semibold  capitalize">
          Professional Experience
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
            <div className="my-4 flex justify-between items-center gap-2">
              <input
                placeholder="Employer"
                {...register("employer_name", { required: true })}
                className="w-72 border p-3 rounded-md  "
              />
              <input
                placeholder="designation"
                {...register("designation", { required: true })}
                className="w-72 border p-3 rounded-md  "
              />
              <span>From</span>
              <input
                placeholder="From"
                {...register("start", { required: true })}
                type="number"
                className="w-52 border p-3 rounded-md  max-md:w-80"
              />
              <span>To</span>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setPresent(e.target.checked)}
                    checked={isPresent}
                  />
                }
                label="Present"
              />
              {isPresent ? (
                <input
                  type="number"
                  placeholder="To"
                  disabled={isPresent}
                  value={currentYear}
                  {...register("end")}
                  className="hidden"
                />
              ) : (
                <input
                  type="number"
                  placeholder="To"
                  {...register("end")}
                  className="w-52 border p-3 rounded-md  max-md:w-80"
                />
              )}
              <button
                type="submit"
                className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg"
              >
                {saving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </form>
        <div className="bg-gray_out">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">
                  <strong>Employer</strong>
                </TableCell>
                <TableCell component="th">
                  <strong>Designation</strong>
                </TableCell>
                <TableCell component="th">
                  <strong>From</strong>
                </TableCell>
                <TableCell component="th">
                  <strong>To</strong>
                </TableCell>
                <TableCell component="th">
                  <strong>Remove</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
            // className="bg-gray_out p-2 rounded-md my-2 flex flex-col gap-3 pl-10"
            >
              {[...newData, ...exp.employers].map((exp, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{exp.employer_name}</TableCell>
                    <TableCell>{exp.designation}</TableCell>
                    <TableCell>{exp.start}</TableCell>
                    <TableCell>{exp.end}</TableCell>
                    <TableCell>
                      <button>
                        <RemoveCircleOutlineOutlined
                          color="error"
                          onClick={() => removeItem(exp.id)}
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isloading || saving ? (
            <>
              <div className="">
                <Skeleton width={"100%"} height={50} />
              </div>
            </>
          ) : [...newData, ...exp.employers].length == 0 ? (
            <div className="flex justify-center w-full py-4">No Data</div>
          ) : null}
        </div>
      </section>
    </div>
  );
}

export default ProfessionalExperience;
