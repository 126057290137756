import CalendarAppointments from "./widgets/CalendarAppointments";
import MessageWidget from "./widgets/MessageWidget";
import MyAssignments from "./widgets/MyAssignments";
import MyProviderList from "./widgets/MyProviderList";

export const tabListPatientDashboard = [
  {
    name: "Home",
    id: 0,
    icon: "/assets/icons/provider/home.png",
    link: "/patient/dashboard",
    pageName: "/dashboard",
  },
  {
    name: "Profile Management",
    id: 1,
    icon: "/assets/icons/provider/profile-management.png",
    link: "/patient/profile-management",
    pageName: "profile-management",
  },

  {
    name: "Provider Management",
    id: 2,
    icon: "/assets/icons/provider/profile-management.png",
    link: "/patient/provider-management",
    pageName: "provider-management",
  },

  {
    name: "Communication & Assignments",
    id: 3,
    icon: "/assets/icons/provider/profile-management.png",
    link: "/patient/communication-assignments",
    pageName: "communication-assignments",
  },

  {
    name: "Plan & Payment",
    id: 4,
    icon: "/assets/icons/provider/profile-management.png",
    link: "/patient/plan-payment",
    pageName: "plan-payment",
  },
];

export const widgets = [
  { id: 1, name: "Calendar", component: <CalendarAppointments /> },
  { id: 2, name: "messages", component: <MessageWidget /> },
  { id: 3, name: "my-assignment", component: <MyAssignments /> },
  { id: 4, name: "my-providers", component: <MyProviderList /> },
];

export const defaultWidgets = [
  {
    id: 1,
    widget_id: "1",
    position: "1",
  },

  {
    id: 2,
    widget_id: "2",
    position: "2",
  },
  {
    id: 3,
    widget_id: "3",
    position: "3",
  },

  {
    id: 4,
    widget_id: "4",
    position: "4",
  },
];
