import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../App";
import DashboardHoc from "../../../DashboardHoc";
import ProfilePicture from "../../../../../components/ProfilePicture";
import PersonalProfileWidget from "../../widgets/PersonalProfileWidget";
import MedicalProfileWidget from "../../widgets/MedicalProfileWidget";
import useWindowDimensions from "../../../../utils/useWindowDimensions";

function ProfileManagement() {
  const { userDetails } = useContext(AuthContext);
  const [width] = useWindowDimensions();

  return (
    <DashboardHoc>
      <div className="w-full flex gap-4 items-center  flex-col p-10 h-4/5  max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">Profile Management</div>
        <div className="text-body_font_three max-md:flow-root  max-md:px-5">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>
        {width > 768 ? (
          <div
            className="flex w-full  gap-3 max-md:flex-col"
            style={{ maxHeight: "500px", minHeight: "500px" }}
          >
            <PersonalProfileWidget />
            <MedicalProfileWidget />
          </div>
        ) : (
          <div className="flex w-full  gap-3 flex-col px-2">
            <PersonalProfileWidget />
            <MedicalProfileWidget />
          </div>
        )}
      </div>
    </DashboardHoc>
  );
}

export default ProfileManagement;
