import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import StatePicker from "../../../../forms/registration-and-verification/StatePicker";
import { AuthContext } from "../../../../../App";
import useProviderActions from "../../../../../actions/useProviderActions";
import { CircularProgress } from "@mui/material";

const PaymentSubscription = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();
  const [charges, setData] = useState({});
  const [avgCharges, setAvgData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [avgIsLoading, setAvgLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const { userDetails } = useContext(AuthContext);
  const {
    saveAssessmentCharges,
    getAssessmentCharges,
    getAverageAssessmentCharges,
  } = useProviderActions();

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getAssessmentCharges(userDetails.user_id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setData(res.data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getAverageAssessmentCharges(userDetails.user_id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setAvgData(res.data);
            }
          }
        })
        .finally(() => {
          setAvgLoading(false);
        });
  }, []);

  const onSubmit = (data) => {
    setSaving(true);
    saveAssessmentCharges({ ...data, user_id: userDetails.user_id }).then(
      (x) => {
        setSaving(false);
      }
    );
  };

  return (
    <div className="h-full bg-white w-full shadow-lg rounded-lg py-10">
      <span className="block font-semibold text-xxl	pl-10">
        Payment & Subscription
      </span>

      <form className="w-full  px-10 mt-2" onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <div className="flex flex-wrap max-md:flex-col my-4 max-md:gap-4 gap-3">
            <div className="w-[30%]">
              <div>Assessment </div>
              <span className="input-symbol-dolor">
                <input
                  className="p-3 rounded-lg max-md:m-0 bg-light_tone_one border-1 border-gray_out w-full h-12  shadow"
                  type="number"
                  defaultValue={charges.assessment_charge}
                  {...register("assessment_charge")}
                />
              </span>
            </div>{" "}
            <div className="w-[30%]">
              <div>Follow Up </div>
              <span className="input-symbol-dolor">
                <input
                  className="p-3 rounded-lg max-md:m-0 bg-light_tone_three border-1 border-gray_out w-full h-12 shadow"
                  type="number"
                  defaultValue={charges.follow_up_charge}
                  {...register("follow_up_charge")}
                />
              </span>
            </div>{" "}
            <div className="w-[30%]">
              <div>Collaboration </div>
              <span className="input-symbol-dolor">
                <input
                  className="p-3 rounded-lg max-md:m-0 bg-light_tone_two border-1 border-gray_out w-full h-12 shadow"
                  type="number"
                  defaultValue={charges.collaboration_charge}
                  {...register("collaboration_charge")}
                />
              </span>
            </div>
          </div>
        )}
        <div className="my-2 w-[30%]">
          <span className="text-body_font_two ">
            Average charges in Your states
          </span>
          <StatePicker showLabel={false} className=" w-full " />
        </div>
        <section className="my-2">
          <div className="flex flex-wrap max-md:flex-col my-4 max-md:gap-4 gap-3 ">
            <div className="bg-light_tone_one p-4 rounded-md w-[30%]">
              <span className="">
                <span className="p-3 rounded-lg max-md:m-0  w-full">
                  &nbsp; Assessment
                </span>
                <span className="bg-white rounded-lg border-2 border-gray_out_text py-3 px-4 ">
                  ${" "}
                  {avgIsLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : avgCharges && avgCharges.avg_assessment_charge ? (
                    Number(avgCharges.avg_assessment_charge).toFixed(2)
                  ) : (
                    0
                  )}
                </span>
              </span>
            </div>{" "}
            <div className="bg-light_tone_three p-4 rounded-md w-[30%]">
              <span className="">
                <span className="p-3 rounded-lg max-md:m-0  w-full">
                  &nbsp; Follow Up
                </span>
                <span className="bg-white rounded-lg border-2 border-gray_out_text py-3 px-4 ">
                  ${" "}
                  {avgIsLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : avgCharges && avgCharges.avg_follow_up_charge ? (
                    Number(avgCharges.avg_follow_up_charge).toFixed(2)
                  ) : (
                    0
                  )}
                </span>
              </span>
            </div>{" "}
            <div className="bg-light_tone_two p-4 rounded-md w-[30%]">
              <span className=" ">
                <span className="p-3 rounded-lg max-md:m-0  w-full">
                  &nbsp; Collaboration
                </span>
                <span className="bg-white rounded-lg border-2 border-gray_out_text py-3 px-4  ">
                  ${" "}
                  {avgIsLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : avgCharges && avgCharges.avg_collaboration_charge ? (
                    Number(avgCharges.avg_collaboration_charge).toFixed(2)
                  ) : (
                    0
                  )}{" "}
                </span>
              </span>
            </div>
          </div>
        </section>
        <input
          disabled={saving}
          type="submit"
          value={saving ? "Saving..." : "Save"}
          className={classNames(
            "text-button_font p-4 submit-btn-bg-color  hover:border-transparent  border rounded-md w-36 my-4 text-light_tone_one bg-mid_tone_two cursor-pointer "
          )}
        ></input>
      </form>
    </div>
  );
};

export default PaymentSubscription;
